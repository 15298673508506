import NumericField from "./NumericField"
import { useField } from "formik"

function FormikNumericField(props) {
  const [, meta, helpers] = useField(props)

  const { value } = meta
  const { setValue } = helpers

  return <NumericField value={value} onChange={setValue} {...props} />
}

export default FormikNumericField
