import { LoadingButton } from "@material-ui/lab"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { FormikTextField } from "../fields"

const validationSchema = Yup.object({
  name: Yup.string().required("Veuillez saisir un nom"),
})

function TruckForm({ truck, onSubmit }) {
  const handleSubmit = async (data) => {
    data = { ...data }

    await onSubmit(data)
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={
        truck ?? {
          name: "",
        }
      }
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormikTextField name="name" label="Nom" />

          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
            pending={isSubmitting}
          >
            {truck ? "Éditer" : "Créer"}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  )
}

export default TruckForm
