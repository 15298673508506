import { Typography } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { gql } from "urql"
import { useQuery, useAction } from "../../../../hooks"
import ProductForm from "../../../../components/admin/ProductForm"

const ProductQuery = gql`
  query ($id: ID!) {
    product(product: { id: $id }) {
      name
      variety
      packaging
      color
      prices {
        first
        second
        third
      }
    }
  }
`

const UpdateProductMutation = gql`
  mutation ($product: UpdateProductInput!) {
    updateProduct(product: $product) {
      id
    }
  }
`

function decodePrice(price) {
  return String(price / 100)
}

function UpdateProduct() {
  const { productId } = useParams()
  let { product } = useQuery(ProductQuery, { id: productId })
  const updateProduct = useAction({
    mutation: UpdateProductMutation,
    successMessage: "Le produit a bien été mis à jour.",
    redirectTo: "/admin/products",
  })

  const handleSubmit = async (product) => {
    await updateProduct({
      product: {
        id: productId,
        ...product,
      },
    })
  }

  product = {
    ...product,
    prices: {
      first: decodePrice(product.prices.first),
      second: decodePrice(product.prices.second),
      third: decodePrice(product.prices.third),
    },
  }

  delete product.__typename

  return (
    <>
      <Typography variant="h2">Éditer un produit</Typography>

      <ProductForm product={product} onSubmit={handleSubmit} />
    </>
  )
}

export default UpdateProduct
