import { HuePicker } from "react-color"

function ColorField({ value, onChange, ...props }) {
  return (
    <HuePicker
      color={value}
      onChange={(color) => {
        onChange(color.hex)
      }}
      width={"100%"}
      {...props}
    />
  )
}

export default ColorField
