import {
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
} from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { Formik, Form } from "formik"
import { pick } from "lodash-es"
import { gql } from "urql"
import { useQuery, useAction } from "../../../hooks"
import { FormikNumericField, FormikTextField } from "../../../components/fields"
import * as Yup from "yup"
import { containers } from "../../../utils"

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

const validationSchema = Yup.object({
  products: Yup.array(
    Yup.object({
      containerCapacity: Yup.mixed().when("packaging", {
        is: "",
        then: Yup.mixed().oneOf(
          containers.map(({ containerCapacity }) => containerCapacity),
          "Veuillez choisir une capacité"
        ),
        otherwise: Yup.mixed().nullable(),
      }),
    })
  ),
})

const ProductsAvailabilityQuery = gql`
  {
    products: avaibaleProducts {
      id
      name
      variety
      packaging

      containerCapacity
      containerCount
      unitsPerContainer
    }
  }
`

const UpdateProductsAvailabilityMutation = gql`
  mutation ($products: [UpdateProductInput!]!) {
    updateProductsAvailability(products: $products) {
      id
    }
  }
`

function ProductsAvailability() {
  const { products } = useQuery(ProductsAvailabilityQuery)
  const updateProductsAvailability = useAction({
    mutation: UpdateProductsAvailabilityMutation,
    successMessage: "La disponibilité des produits a bien été mise à jour.",
  })

  const initialValues = {
    products: products.map((product) => {
      const newProduct = pick(product, [
        "id",
        "containerCapacity",
        "containerCount",
        "unitsPerContainer",
      ])
      if (newProduct.unitsPerContainer === 0) {
        newProduct.unitsPerContainer = 1
      }
      return newProduct
    }),
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Disponibilité des produits
      </Typography>

      <Formik
        validationSchema={validationSchema}
        onSubmit={updateProductsAvailability}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Produit</TableCell>
                    <TableCell>Unités par conteneur</TableCell>
                    <TableCell>Capacité du conteneur (si au poids)</TableCell>
                    <TableCell>Nombre de conteneurs</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {products.map((product, index) => {
                    let name = product.name
                    if (product.variety) {
                      name += ` - ${product.variety}`
                    }
                    if (product.packaging) {
                      name += ` (${product.packaging})`
                    }

                    return (
                      <TableRow key={product.id} sx={hideLastBorder}>
                        <TableCell>{name}</TableCell>

                        <TableCell sx={{ py: 0 }}>
                          <FormikNumericField
                            name={`products[${index}].unitsPerContainer`}
                          />
                        </TableCell>

                        <TableCell sx={{ p: 0 }}>
                          {product.packaging === "" && (
                            <FormikTextField
                              name={`products[${index}].containerCapacity`}
                              select
                            >
                              {containers.map((container) => (
                                <MenuItem
                                  key={container.capacity}
                                  value={container.capacity}
                                >
                                  {container.format} {container.capacity}{" "}
                                  {container.unit}
                                </MenuItem>
                              ))}
                            </FormikTextField>
                          )}
                        </TableCell>

                        <TableCell sx={{ py: 0 }}>
                          <FormikNumericField
                            name={`products[${index}].containerCount`}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              pending={isSubmitting}
            >
              Enregistrer
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ProductsAvailability
