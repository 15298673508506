import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core"
import { useQuery } from "../../../../hooks"
import { useHistory, useParams } from "react-router-dom"
import { gql } from "urql"
import DistributionTitleWithDate from "../../../../components/admin/DistributionTitleWithDate"

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

const StockDistributionSellingPointsQuery = gql`
  query ($weekDay: WeekDay!) {
    deliveriesTurnover
    trucks {
      deliveries(weekDay: $weekDay) {
        id

        sellingPoint {
          id
          name
        }
      }
    }
  }
`

function StockDistributionSellingPoints() {
  const history = useHistory()
  const { weekDay } = useParams()
  const { trucks, deliveriesTurnover } = useQuery(
    StockDistributionSellingPointsQuery,
    {
      weekDay: weekDay.toUpperCase(),
    }
  )

  const handleClick = ({ sellingPointId, deliveryId }) => {
    history.push(
      `/admin/logistic/stock-distribution/${weekDay}/${sellingPointId}/${deliveryId}`
    )
  }

  let deliveries = []

  for (const truck of trucks) {
    deliveries = deliveries.concat(truck.deliveries)
  }

  return (
    <>
      <DistributionTitleWithDate />

      <Typography variant="h4" gutterBottom>
        {`CA estimé pour ce point de vente: ${(
          deliveriesTurnover / 100
        ).toFixed(2)}€`}
      </Typography>

      <Typography variant="h6" gutterBottom>
        Liste des points de ventes
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="Points de ventes">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {deliveries.map((delivery) => (
              <TableRow key={delivery.id} sx={hideLastBorder}>
                <TableCell>{delivery.sellingPoint.name}</TableCell>

                <TableCell sx={{ py: 0 }}>
                  <Button
                    onClick={() =>
                      handleClick({
                        sellingPointId: delivery.sellingPoint.id,
                        deliveryId: delivery.id,
                      })
                    }
                    variant="contained"
                  >
                    Gerer les stocks à livrer
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default StockDistributionSellingPoints
