import { Typography } from "@material-ui/core"

function PasswordResetEmailConfirmed() {
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Un email vous a été envoyé.
      </Typography>
      <Typography variant="h2">
        Veuillez regarder vos mails et cliquer sur le lien de la
        réinitialisation
      </Typography>
    </>
  )
}

export default PasswordResetEmailConfirmed
