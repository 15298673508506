import { Typography, Link, Box } from "@material-ui/core"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { useSignInWithEmailAndPassword } from "../../hooks"
import { FormikTextField } from "../../components/fields"
import logo from "../../logo_2500_texte.png" // Tell webpack this JS file uses this image
import { makeStyles } from "@material-ui/core/styles"
import { LoadingButton } from "@material-ui/lab"

const useStyles = makeStyles((theme) => ({
  logo: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "50px",
  },
}))

const validationSchema = Yup.object({
  email: Yup.string()
    .required("Veuillez saisir une adresse email")
    .email("Veuillez saisir une adresse email valide"),
  password: Yup.string().required("Veuillez saisir un mot de passe"),
})

function SignInForm() {
  const signInWithEmailAndPassword = useSignInWithEmailAndPassword()

  const handleSubmit = async ({ email, password }) => {
    await signInWithEmailAndPassword(email, password)
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ email: "", password: "" }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormikTextField
            label="Adresse email"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
          />

          <FormikTextField
            label="Mot de passe"
            name="password"
            type="password"
            autoComplete="current-password"
          />

          <LoadingButton
            pending={isSubmitting}
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            sx={{ my: 2 }}
          >
            Connexion
          </LoadingButton>

          <Link href="/auth/send-password-reset">Mot de passe oublié ?</Link>
        </Form>
      )}
    </Formik>
  )
}

function SignIn() {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.logo}>
        <img src={logo} alt="Logo" width="200" />
      </Box>

      <Typography component="h1" variant="h5" gutterBottom align="center">
        Connexion
      </Typography>

      <SignInForm />
    </>
  )
}

export default SignIn
