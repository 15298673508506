import { Typography } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { produce } from "immer"
import { gql } from "urql"
import { useQuery, useAction } from "../../../../hooks"
import UserForm from "../../../../components/admin/UserForm"

const UserQuery = gql`
  query ($id: ID!) {
    user(user: { id: $id }) {
      email
      firstName
      lastName
      phoneNumber
      role

      managerOf {
        id
      }

      sellerAt {
        id
      }
    }
  }
`

const UpdateUserMutation = gql`
  mutation ($user: UpdateUserInput!) {
    updateUser(user: $user) {
      id
    }
  }
`

function UpdateUser() {
  const { userId } = useParams()
  let { user } = useQuery(UserQuery, { id: userId })
  const updateUser = useAction({
    mutation: UpdateUserMutation,
    successMessage: "L’utilisateur a bien été mis à jour.",
    redirectTo: "/admin/users",
  })

  const handleSubmit = async (user) => {
    await updateUser({
      user: {
        id: userId,
        ...user,
      },
    })
  }

  user = produce(user, (draft) => {
    delete user.__typename

    draft.managerOf = draft.managerOf.map(({ id }) => id)
    draft.sellerAt = draft.sellerAt.map(({ id }) => id)

    if (!draft.role) {
      draft.role = ""
    }
  })

  return (
    <>
      <Typography variant="h2">Éditer un utilisateur</Typography>

      <UserForm user={user} onSubmit={handleSubmit} />
    </>
  )
}

export default UpdateUser
