import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { Formik, Form } from "formik"
import { gql } from "urql"
import { useQuery, useAction } from "../../../hooks"
import { FormikNumericField, ContainerField } from "../../../components/fields"
import { useParams } from "react-router-dom"
import { containers } from "../../../utils"

const CheckUnsoldStockMutation = gql`
  mutation ($unsoldStock: CheckUnsoldStockInput!) {
    checkUnsoldStock(unsoldStock: $unsoldStock) {
      id
    }
  }
`

const StockCheckingQuery = gql`
  query ($salesDayId: ID!) {
    salesDay(id: $salesDayId) {
      id
      items: initialProducts {
        id
        packaging
        fullName
      }
    }
  }
`

function StockChecking() {
  const { salesDayId } = useParams()

  const {
    salesDay: { items },
  } = useQuery(StockCheckingQuery, { salesDayId })

  const checkUnsoldStock = useAction({
    mutation: CheckUnsoldStockMutation,
    successMessage: "Les invendus ont bien été mis à jour.",
  })

  const handleSubmit = async ({ items }) => {
    for (const item of items) {
      if (item.weight === null) {
        continue
      }
      let totalWeight = 0
      for (const [name, count] of Object.entries(item.weight)) {
        const { quantity, capacity } = containers.find(
          (container) => container.name === name
        )
        totalWeight += count * quantity * capacity
      }
      item.weight = totalWeight
      item.quantity = null
    }
    await checkUnsoldStock({
      unsoldStock: {
        salesDayId,
        items,
      },
    })
  }

  const initialValues = {
    items: items.map((item) => ({
      productId: item.id,
      weight:
        item.packaging === null
          ? Object.assign({}, ...containers.map(({ name }) => ({ [name]: 0 })))
          : null,
      quantity: 0,
    })),
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Contrôle des stocks - Invendu
      </Typography>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, isSubmitting }) => (
          <Form>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Produit</TableCell>
                    <TableCell>Invendus</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {items.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.fullName}</TableCell>
                      <TableCell>
                        {item.packaging === null ? (
                          <ContainerField
                            name={`items[${index}].weight`}
                            containerCounts={values.items[index].weight}
                          />
                        ) : (
                          <FormikNumericField
                            name={`items[${index}].quantity`}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <LoadingButton
              pending={isSubmitting}
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Valider
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default StockChecking
