import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core"
import { gql } from "urql"
import { LoadingButton } from "@material-ui/lab"
import { Formik, Form } from "formik"
import { useParams } from "react-router-dom"
import { useQuery, useAction } from "../../../hooks"
import { FormikNumericField } from "../../../components/fields"

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

const StockCheckingQuery = gql`
  query ($sellingPointId: ID!) {
    sellingPoint(sellingPoint: { id: $sellingPointId }) {
      id

      delivery {
        id

        items {
          product {
            id
            fullName
          }
        }
      }
    }
  }
`

const CheckAffectedStockMutation = gql`
  mutation ($affectedStock: CheckAffectedStockInput!) {
    checkAffectedStock(affectedStock: $affectedStock) {
      id
    }
  }
`

function StockChecking({ redirectTo }) {
  const { sellingPointId } = useParams()
  const {
    sellingPoint: { delivery },
  } = useQuery(StockCheckingQuery, {
    sellingPointId,
  })
  const checkAffectedStock = useAction({
    mutation: CheckAffectedStockMutation,
    successMessage: "Le stock a bien été vérifié.",
    redirectTo,
  })

  if (delivery === null) {
    return null
  }
  const { items } = delivery

  const handleSubmit = async ({ items }) => {
    await checkAffectedStock({
      affectedStock: {
        sellingPointId,
        items,
      },
    })
  }

  const initialValues = {
    items: items.map(({ product }) => ({
      productId: product.id,
      containerCount: 0,
    })),
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Contrôle des stocks - Camion
      </Typography>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Nombre de conteneurs</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {items.map(({ product }, index) => (
                    <TableRow key={product.id} sx={hideLastBorder}>
                      <TableCell>{product.fullName}</TableCell>
                      <TableCell sx={{ py: 0 }}>
                        <FormikNumericField
                          name={`items[${index}].containerCount`}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <LoadingButton
              type="submit"
              variant="contained"
              pending={isSubmitting}
              sx={{ mt: 2 }}
            >
              Valider
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default StockChecking
