import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser"
import { Link as RouterLink } from "react-router-dom"
import { gql } from "urql"
import { useQuery, useMutation } from "../../../hooks"
import DeleteButton from "../../../components/admin/DeleteButton"
import FabCreate from "../../../components/admin/FabCreate"

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

function UpdateUserButton({ userId }) {
  return (
    <IconButton
      size="small"
      component={RouterLink}
      to={`/admin/users/${userId}/update`}
    >
      <EditIcon />
    </IconButton>
  )
}

const DeleteUserMutation = gql`
  mutation ($id: ID!) {
    deleteUser(user: { id: $id }) {
      id
    }
  }
`

function DeleteUserButton({ userId }) {
  const deleteUser = useMutation(DeleteUserMutation)

  return <DeleteButton onClick={() => deleteUser({ id: userId })} />
}

const UsersQuery = gql`
  {
    me {
      id
      role
    }

    users {
      id
      email
      hasAlreadySignedIn
      fullName
    }
  }
`

function Users() {
  const { users, me } = useQuery(UsersQuery)

  const isSuperAdmin = me.role === "SUPER_ADMIN"
  const isAdmin = me.role === "ADMIN" || isSuperAdmin

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Liste des utilisateurs
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="users">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Adresse email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id} sx={hideLastBorder}>
                <TableCell>{user.fullName}</TableCell>

                <TableCell>
                  {/* <Typography>
                  </Typography> */}
                  {user.email}
                  {user.hasAlreadySignedIn && (
                    <VerifiedUserIcon sx={{ ml: 1 }} fontSize={"small"} />
                  )}
                </TableCell>

                <TableCell sx={{ py: 0 }}>
                  <UpdateUserButton userId={user.id} />
                  {me.id !== user.id &&
                    (isSuperAdmin || (isAdmin && !user.isAdmin)) && (
                      <DeleteUserButton userId={user.id} />
                    )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <FabCreate route="/admin/users/create" />
    </>
  )
}

export default Users
