const weekDays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
]

export function weekDay() {
  const now = new Date()

  return weekDays[now.getDay()]
}

export function nextWeekDay() {
  const now = new Date()
  const currentDay = (now.getDay() + 1) % 7

  return weekDays[currentDay]
}
