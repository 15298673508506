import { Formik, Form } from "formik"
import * as Yup from "yup"
import { useSendPasswordResetEmail } from "../../hooks"

import { Typography } from "@material-ui/core"
import { FormikTextField } from "../../components/fields"
import { LoadingButton } from "@material-ui/lab"

const validationSchema = Yup.object({
  email: Yup.string()
    .required("Veuillez saisir une adresse email")
    .email("Veuillez saisir une adresse email valide"),
})

function SendPasswordReset() {
  const sendPasswordResetEmail = useSendPasswordResetEmail()

  const handleSubmit = async ({ email }) => {
    await sendPasswordResetEmail(email)
  }

  return (
    <>
      <Typography component="h1" variant="h5" gutterBottom align="center">
        Mot de passe oublié
      </Typography>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormikTextField
              label="Adresse email"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
            />
            <LoadingButton
              pending={isSubmitting}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              sx={{ my: 2 }}
            >
              Réinitialiser le mot de passe
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default SendPasswordReset
