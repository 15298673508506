import { makeStyles } from "@material-ui/core/styles"
import { Container } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(4),
    },

    [theme.breakpoints.up("sm")]: {
      minHeight: "100vh",
    },

    display: "flex",
    alignItems: "center",
  },
}))

function Auth({ children }) {
  const classes = useStyles()

  return (
    <Container className={classes.container} component="main" maxWidth="sm">
      <div>{children}</div>
    </Container>
  )
}

export default Auth
