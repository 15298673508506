import { Typography, Grid, Card } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { useUserRoles } from "../hooks"

const cardStyles = {
  minHeight: 100,
  marginTop: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
}

function RoleCard({ title, link }) {
  const history = useHistory()
  function handleClick() {
    history.push(link)
  }

  return (
    <>
      <Card onClick={handleClick} sx={cardStyles}>
        <Typography style={{}} variant="h4">
          {title}
        </Typography>
      </Card>
    </>
  )
}

function Roles() {
  const history = useHistory()
  const userRoles = useUserRoles()

  if (userRoles.length === 0) {
    history.push("/roles/no-role")
  }

  if (userRoles.length === 1) {
    history.push(userRoles[0].link)
  }

  return (
    <>
      <Typography component="h1" variant="h2" gutterBottom>
        Choisissez votre profil
      </Typography>

      <Grid container>
        {userRoles.map(({ title, link }) => (
          <Grid key={link} item xs={12}>
            <RoleCard title={title} link={link} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default Roles
