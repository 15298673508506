import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { Formik, Form } from "formik"
import {
  FormikNumericField,
  FormikCashFundField,
} from "../../../../components/fields"
import { useQuery, useAction } from "../../../../hooks"
import { gql } from "urql"
import { useParams } from "react-router-dom"
import DistributionTitleWithDate from "../../../../components/admin/DistributionTitleWithDate"
import WarningIcon from "@material-ui/icons/Warning"
import { format } from "../../../../utils"

function AlertMessage({ message }) {
  return (
    <Typography variant="h4" gutterBottom sx={{ color: "red" }}>
      <WarningIcon fontSize="large" />
      {message}
    </Typography>
  )
}

const StockDistributionQuery = gql`
  query ($deliveryId: ID!, $sellingPoint: ID!) {
    delivery(delivery: { id: $deliveryId }) {
      weekDay

      sellingPoint {
        name
      }

      items {
        containerCount

        product {
          id
        }
      }

      forecasts {
        sold
        need

        product {
          id
        }
      }

      lastWeekWeatherQuality
    }

    products: deliveryProducts(sellingPoint: $sellingPoint) {
      id
      fullName
      price

      containerCapacity
      containerCount
      unitsPerContainer
    }
  }
`

const UpdateStockDistributionMutation = gql`
  mutation ($delivery: UpdateDeliveryInput!) {
    updateDelivery(delivery: $delivery) {
      id
    }
  }
`

function StockDistribution() {
  const { deliveryId, sellingPointId } = useParams()

  const {
    delivery: {
      weekDay,
      sellingPoint,
      items,
      forecasts,
      lastWeekWeatherQuality,
    },
    products,
  } = useQuery(StockDistributionQuery, {
    deliveryId,
    sellingPoint: sellingPointId,
  })
  const updateStockDistribution = useAction({
    mutation: UpdateStockDistributionMutation,
    successMessage: "Les stocks ont bien été enregistrés.",
    redirectTo: `/admin/logistic/stock-distribution/${weekDay.toLowerCase()}`,
  })

  const handleSubmit = async ({ affectedCashFund, products }) => {
    affectedCashFund = Number(affectedCashFund) * 100

    await updateStockDistribution({
      delivery: {
        id: deliveryId,
        affectedCashFund,
        items: products.map(({ id, ...product }) => ({
          productId: id,
          ...product,
        })),
      },
    })
  }

  const initialValues = {
    affectedCashFund: "",
    products: products.map(
      ({ id, unitsPerContainer, containerCapacity }) => ({
        id,
        containerCount: 0,
        unitsPerContainer,
        containerCapacity,
      })
    ),
  }

  for (const {
    product: { id: productId },
    containerCount,
  } of items) {
    const product = initialValues.products.find(
      (product) => product.id === productId
    )

    if (!product) {
      continue;
    }

    product.containerCount = containerCount
  }

  function estimatedTurnover(affectedProducts) {
    let total = 0
    for (const {
      id,
      unitsPerContainer,
      containerCapacity,
      containerCount,
    } of affectedProducts) {
      const price = products.find((product) => product.id === id).price
      total +=
        price * unitsPerContainer * (containerCapacity ?? 1) * containerCount
    }
    return total
  }

  return (
    <>
      <DistributionTitleWithDate />

      <Typography variant="h2" gutterBottom>
        {sellingPoint.name}
      </Typography>

      {sellingPoint.bigBagAlert && (
        <AlertMessage message="Stock sacs 2 kg faible !" />
      )}

      {sellingPoint.smallBagAlert && (
        <AlertMessage message="Stock sacs 1 kg faible !" />
      )}

      {lastWeekWeatherQuality !== null && (
        <Typography variant="h4" gutterBottom>
          Météo la semaine dernière : {lastWeekWeatherQuality} / 10
        </Typography>
      )}

      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ values, isSubmitting }) => (
          <Form>
            <Typography variant="h4" gutterBottom>
              {`CA estimé pour ce point de vente: ${format.price(
                estimatedTurnover(values.products)
              )}€`}
            </Typography>

            <FormikCashFundField
              name="affectedCashFund"
              label="Fond de caisse à envoyer"
            />

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Prix</TableCell>
                    <TableCell>Dispo</TableCell>
                    <TableCell>Vendus S-1</TableCell>
                    <TableCell>Besoin estimé</TableCell>
                    <TableCell>Affectation</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {products.map((product, index) => {
                    const forecast = forecasts.find(
                      ({ product: { id } }) => id === product.id
                    ) ?? { sold: 0, need: 0 }

                    return (
                      <TableRow key={product.id}>
                        <TableCell>{product.fullName}</TableCell>
                        <TableCell>{product.price / 100}€</TableCell>
                        <TableCell>{product.containerCount}</TableCell>
                        <TableCell>{forecast.sold}</TableCell>
                        <TableCell>{forecast.need}</TableCell>
                        <TableCell sx={{ py: 0 }}>
                          <FormikNumericField
                            name={`products[${index}].containerCount`}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              pending={isSubmitting}
            >
              Enregistrer
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default StockDistribution
