import { useState } from "react"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from "@material-ui/core"
import { gql } from "urql"
import { useAction, useQuery } from "../../../hooks"
import DeleteButton from "../../../components/admin/DeleteButton"

const DeleteAlertMutation = gql`
  mutation ($alertId: ID!) {
    deleteAlert(alert: { id: $alertId }) {
      id
    }
  }
`

function DeleteAlertButton({ alertId }) {
  const deleteAlert = useAction({
    mutation: DeleteAlertMutation,
    successMessage: "L'alerte a bien été supprimée.",
  })

  const handleClick = async () => {
    await deleteAlert({ alertId })
  }

  return <DeleteButton onClick={handleClick} />
}

const AlertsQuery = gql`
  {
    alerts {
      id
      date
      message

      sellingPoint {
        id
        name
      }
    }
  }
`

function Alerts() {
  const { alerts } = useQuery(AlertsQuery)
  const [page, setPage] = useState(0)

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Liste des alertes
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="products">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Point de vente</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {alerts.slice(page * 10, page * 10 + 10).map((alert) => (
              <TableRow key={alert.id}>
                <TableCell>
                  {format(new Date(alert.date), "'Le' PPP", {
                    locale: fr,
                  })}
                </TableCell>
                <TableCell>{alert.sellingPoint.name}</TableCell>
                <TableCell>{alert.message}</TableCell>
                <TableCell sx={{ py: 0 }}>
                  <DeleteAlertButton alertId={alert.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                count={alerts.length}
                rowsPerPage={10}
                page={page}
                onPageChange={(event, page) => setPage(page)}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}

export default Alerts
