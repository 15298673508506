import { useHistory, useParams } from "react-router-dom"
import { Button } from "@material-ui/core"

import InnerLayout from "../../../components/seller/InnerLayout"

function StartNewDelivery() {
  const history = useHistory()
  const { sellingPointId } = useParams()

  function handleClick() {
    history.push(`/manager/selling-points/${sellingPointId}/new-delivery`)
  }
  return (
    <InnerLayout>
      <Button onClick={handleClick}>
        Commencer un nouveau contrôle de livraison
      </Button>
    </InnerLayout>
  )
}

export default StartNewDelivery
