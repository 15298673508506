import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { Formik, Form } from "formik"
import { useParams } from "react-router-dom"
import { gql } from "urql"
import { useQuery, useAction } from "../../../../../hooks"
import { FormikNumericField } from "../../../../../components/fields"
import { format } from "../../../../../utils"

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}
const StockDistributionQuery = gql`
  query ($sellingPointId: ID!) {
    sellingPoint(sellingPoint: { id: $sellingPointId }) {
      id

      products {
        id
        fullName
        price

        containerCapacity
        containerCount
        unitsPerContainer
      }

      deliveriesItems {
        containerCount

        product {
          id
        }
      }
    }

    products {
      id
      fullName
      price

      containerCapacity
      containerCount
      unitsPerContainer
    }
  }
`

const UpdateSellingPointDeliveriesItemsMutation = gql`
  mutation ($items: UpdateSellingPointDeliveriesItemsInput!) {
    updateSellingPointDeliveriesItems(items: $items) {
      id
    }
  }
`

function UpdateSellingPointDeliveriesItemsView() {
  const { sellingPointId } = useParams()

  const { sellingPoint, products } = useQuery(StockDistributionQuery, {
    sellingPointId,
  })
  const updateStockDistribution = useAction({
    mutation: UpdateSellingPointDeliveriesItemsMutation,
    successMessage: "Les stocks ont bien été enregistrés.",
    redirectTo: `/admin/selling-points`,
  })

  const handleSubmit = async ({ products }) => {
    await updateStockDistribution({
      items: {
        sellingPointId,
        items: products.map(({ id, ...product }) => ({
          productId: id,
          ...product,
        })),
      },
    })
  }

  const initialValues = {
    products: products.map(
      ({ id, containerCount, unitsPerContainer, containerCapacity }) => ({
        id,
        containerCount: 0,
        unitsPerContainer,
        containerCapacity,
      })
    ),
  }

  for (const {
    product: { id: productId },
    containerCount,
  } of sellingPoint.deliveriesItems) {
    const product = initialValues.products.find(
      (product) => product.id === productId
    )
    product.containerCount = containerCount
  }

  function estimatedTurnover(affectedProducts) {
    let total = 0
    for (const {
      id,
      unitsPerContainer,
      containerCapacity,
      containerCount,
    } of affectedProducts) {
      const { price } = sellingPoint.products.find(
        (product) => product.id === id
      )

      total +=
        price * unitsPerContainer * (containerCapacity ?? 1) * containerCount
    }
    return total
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Éditer la livraison du jour
      </Typography>

      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ values, isSubmitting }) => (
          <Form>
            <Typography variant="h5" gutterBottom>
              {`CA estimé pour ce point de vente: ${format.price(
                estimatedTurnover(values.products)
              )}€`}
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Produit</TableCell>
                    <TableCell>Dispo</TableCell>
                    <TableCell>Affectation</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {products.map((product, index) => (
                    <TableRow key={product.id} sx={hideLastBorder}>
                      <TableCell>{product.fullName}</TableCell>
                      <TableCell>{product.containerCount}</TableCell>
                      <TableCell sx={{ py: 0 }}>
                        <FormikNumericField
                          name={`products[${index}].containerCount`}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <LoadingButton
              variant="contained"
              sx={{ mt: 2 }}
              pending={isSubmitting}
              type="submit"
            >
              Enregistrer
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default UpdateSellingPointDeliveriesItemsView
