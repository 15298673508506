import { useState, Suspense } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container } from "@material-ui/core"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import GroupIcon from "@material-ui/icons/Group"
import StorefrontIcon from "@material-ui/icons/Storefront"
import ListAltIcon from "@material-ui/icons/ListAlt"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import AssignmentIcon from "@material-ui/icons/Assignment"
import StorageIcon from "@material-ui/icons/Storage"
import CloudIcon from "@material-ui/icons/Cloud"
import DvrIcon from "@material-ui/icons/Dvr"
import ErrorIcon from "@material-ui/icons/Error"
import DescriptionIcon from "@material-ui/icons/Description"
import ReceiptIcon from "@material-ui/icons/Receipt"
import AppBar from "../components/layout/AppBar"
import Drawer from "../components/layout/Drawer"
import { nextWeekDay } from "../utils"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  main: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
  },

  toolbar: theme.mixins.toolbar,
}))

function Admin({ children }) {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen)
  }

  const links = [
    {
      name: "Alertes",
      icon: <ErrorIcon />,
      to: "/admin/alerts",
    },
    {
      name: "Produits",
      icon: <ShoppingCartIcon />,
      to: "/admin/products",
    },
    {
      name: "Utilisateurs",
      icon: <GroupIcon />,
      to: "/admin/users",
    },
    {
      name: "Points de ventes",
      icon: <StorefrontIcon />,
      to: "/admin/selling-points",
    },
    {
      name: "Ventes en cours",
      icon: <ReceiptIcon />,
      to: "/admin/sales-days/current",
    },
    {
      name: "Liste des camions",
      icon: <LocalShippingIcon />,
      to: "/admin/trucks",
    },
    {
      name: "Logistique",
      icon: <DvrIcon />,
      links: [
        {
          name: "Prévisions",
          icon: <CloudIcon />,
          to: "/admin/logistic/forecasts",
        },
        {
          name: "Disponibilité des produits",
          icon: <StorageIcon />,
          to: "/admin/logistic/products-availability",
        },
        {
          name: "Livraisons",
          icon: <ListAltIcon />,
          to: "/admin/logistic/delivery-days/monday",
        },
        {
          name: "Répartition des stocks",
          icon: <AssignmentIcon />,
          to: `/admin/logistic/stock-distribution/${nextWeekDay()}`,
        },
      ],
    },
    {
      name: "Exportation de données",
      icon: <DescriptionIcon />,
      to: "/admin/export-csv",
    },
  ]

  return (
    <div className={classes.root}>
      <AppBar links={links} onDrawerToggle={handleDrawerToggle} />
      <Drawer open={drawerOpen} onClose={handleDrawerToggle} links={links} />

      <Container component="main" maxWidth="md" className={classes.main}>
        <div className={classes.toolbar} />

        <Suspense fallback={<div />}>{children}</Suspense>
      </Container>
    </div>
  )
}

export default Admin
