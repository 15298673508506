import { TextField, InputAdornment, IconButton } from "@material-ui/core"
import RemoveIcon from "@material-ui/icons/Remove"
import AddIcon from "@material-ui/icons/Add"

function NumericField({ onChange, value, ...props }) {
  const handlePlus = () => {
    onChange(value + 1)
  }

  const handleMinus = () => {
    if (value >= 1) {
      onChange(value - 1)
    }
  }

  return (
    <TextField
      onChange={(event) => {
        const value = parseInt(event.target.value)

        if (isNaN(value) || value < 0) {
          onChange(0)
        } else {
          onChange(value)
        }
      }}
      value={value}
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
        style: { textAlign: "center" },
      }}
      style={{
        minWidth: "180px",
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={handleMinus}>
              <RemoveIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handlePlus}>
              <AddIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}

export default NumericField
