import { Switch, Route } from "react-router-dom"
import PrivateRoute from "./components/PrivateRoute"

import AdminLayout from "./layouts/Admin"
// Alerts
import AlertsView from "./views/admin/alerts"
// Products
import ProductsView from "./views/admin/products"
import CreateProductView from "./views/admin/products/create"
import UpdateProductView from "./views/admin/products/_productId/update"
// User
import UsersView from "./views/admin/users"
import CreateUserView from "./views/admin/users/create"
import UpdateUserView from "./views/admin/users/_userId/update"
// Selling points
import SellingPointsView from "./views/admin/sellingPoints"
import CreateSellingPointView from "./views/admin/sellingPoints/create"
import UpdateSellingPointView from "./views/admin/sellingPoints/_sellingPointId/update"
import UpdateSellingPointItemsView from "./views/admin/sellingPoints/_sellingPointId/items/update"
import UpdateSellingPointDeliveriesItemsView from "./views/admin/sellingPoints/_sellingPointId/deliveriesItems/update"
import SellingPointSalesDaysView from "./views/admin/sellingPoints/_sellingPointId/salesDays"
import UpdateSellingPointSalesDaysView from "./views/admin/sellingPoints/_sellingPointId/salesDays/_salesDayId/update"
// Dashboards
import CurrentSalesDaysDashboardView from "./views/admin/salesDays/current"
// Truck
import TrucksView from "./views/admin/trucks"
import CreateTruckView from "./views/admin/trucks/create"
import UpdateTruckView from "./views/admin/trucks/_truckId/update"
// Forecasts
import ForecastsView from "./views/admin/logistic/forecasts"
import SellingPointForecastsView from "./views/admin/logistic/forecasts/_sellingPointId"
// Delivery
import ProductsAvailabilityView from "./views/admin/logistic/productsAvailability"
import StockDistributionView from "./views/admin/logistic/stockDistribution/_sellingPointId"
import StockDistributionSellingPoints from "./views/admin/logistic/stockDistribution"
import DeliveryDayView from "./views/admin/logistic/deliveryDays"
// CSV
import ExportCsvView from "./views/admin/csv"

function AdminRoute() {
  return (
    <PrivateRoute path="/admin">
      <AdminLayout>
        <Switch>
          <Route path="/admin/alerts" exact>
            <AlertsView />
          </Route>

          <Route path="/admin/products" exact>
            <ProductsView />
          </Route>

          <Route path="/admin/products/create" exact>
            <CreateProductView />
          </Route>

          <Route path="/admin/products/:productId/update" exact>
            <UpdateProductView />
          </Route>

          <Route path="/admin/users" exact>
            <UsersView />
          </Route>

          <Route path="/admin/users/create" exact>
            <CreateUserView />
          </Route>

          <Route path="/admin/users/:userId/update" exact>
            <UpdateUserView />
          </Route>

          <Route path="/admin/selling-points" exact>
            <SellingPointsView />
          </Route>

          <Route path="/admin/selling-points/create" exact>
            <CreateSellingPointView />
          </Route>

          <Route path="/admin/selling-points/:sellingPointId/update" exact>
            <UpdateSellingPointView />
          </Route>

          <Route path="/admin/sales-days/current" exact>
            <CurrentSalesDaysDashboardView />
          </Route>

          <Route path="/admin/selling-points/:sellingPointId/sales-days" exact>
            <SellingPointSalesDaysView />
          </Route>

          <Route
            path="/admin/selling-points/:sellingPointId/sales-days/:salesDayId/update"
            exact
          >
            <UpdateSellingPointSalesDaysView />
          </Route>

          <Route
            path="/admin/selling-points/:sellingPointId/items/update"
            exact
          >
            <UpdateSellingPointItemsView />
          </Route>

          <Route
            path="/admin/selling-points/:sellingPointId/deliveries-items/update"
            exact
          >
            <UpdateSellingPointDeliveriesItemsView />
          </Route>

          <Route path="/admin/trucks" exact>
            <TrucksView />
          </Route>

          <Route path="/admin/trucks/create" exact>
            <CreateTruckView />
          </Route>

          <Route path="/admin/trucks/:truckId/update" exact>
            <UpdateTruckView />
          </Route>

          <Route path="/admin/logistic/forecasts" exact>
            <ForecastsView />
          </Route>

          <Route path="/admin/logistic/forecasts/:sellingPointId" exact>
            <SellingPointForecastsView />
          </Route>

          <Route path="/admin/logistic/products-availability" exact>
            <ProductsAvailabilityView />
          </Route>

          <Route path="/admin/logistic/stock-distribution/:weekDay" exact>
            <StockDistributionSellingPoints />
          </Route>

          <Route
            path="/admin/logistic/stock-distribution/:weekDay/:sellingPointId/:deliveryId"
            exact
          >
            <StockDistributionView />
          </Route>

          <Route path="/admin/logistic/delivery-days/:weekDay" exact>
            <DeliveryDayView />
          </Route>

          <Route path="/admin/export-csv" exact>
            <ExportCsvView />
          </Route>
        </Switch>
      </AdminLayout>
    </PrivateRoute>
  )
}

export default AdminRoute
