import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { FormikNumericField, FormikMoneyField } from "../../fields"
import { Formik, Form } from "formik"

function ReduceTotalPriceDialog({ open, onClose, onValidate, values }) {
  function reducedTotalPrice({
    totalPrice,
    eurosDiscount,
    percentageDiscount,
  }) {
    return (totalPrice - eurosDiscount * 100) * (1 - percentageDiscount / 100)
  }

  function handleSubmit(values) {
    onValidate({
      ...values,
    })

    onClose()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="cash-fund-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <Formik
          initialValues={{
            totalPrice: values.totalPrice,
            percentageDiscount: values.percentageDiscount ?? 0,
            eurosDiscount: values.eurosDiscount ?? 0,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, isSubmitting }) => (
            <Form>
              <DialogTitle id="cash-fund-dialog-title">{`Prix total du ticket ${
                values.totalPrice / 100
              }€`}</DialogTitle>

              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormikNumericField
                      name="percentageDiscount"
                      label="Réduction en pourcentage"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikMoneyField
                      name="eurosDiscount"
                      label="Réduction en euros"
                    />
                  </Grid>
                </Grid>

                <Typography variant="h6">{`Total réduit : ${
                  reducedTotalPrice(values) / 100
                }€`}</Typography>
              </DialogContent>
              <DialogActions>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  pending={isSubmitting}
                >
                  Valider
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default ReduceTotalPriceDialog
