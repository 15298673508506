import { makeStyles } from "@material-ui/core/styles"
import { Container, Typography } from "@material-ui/core"
import { gql } from "urql"
import { useParams } from "react-router-dom"
import { useQuery } from "../hooks"
import AppBar from "../components/layout/AppBar"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // height: "100%",
  },

  main: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },

  toolbar: theme.mixins.toolbar,
}))

const SellingPointQuery = gql`
  query ($sellingPoint: SellingPointInput!) {
    sellingPoint(sellingPoint: $sellingPoint) {
      id
      name
    }
  }
`

function SellingPointName({ sellingPointId }) {
  const {
    sellingPoint: { name },
  } = useQuery(SellingPointQuery, { sellingPoint: { id: sellingPointId } })

  return <Typography>{name}</Typography>
}

function Seller({ children }) {
  const classes = useStyles()

  const { sellingPointId } = useParams()

  return (
    <div className={classes.root}>
      <AppBar>
        {!!sellingPointId && (
          <SellingPointName sellingPointId={sellingPointId} />
        )}
      </AppBar>

      <Container component="main" maxWidth="xl" className={classes.main}>
        <div className={classes.toolbar} />

        {children}
      </Container>
    </div>
  )
}

export default Seller
