import { useParams, useHistory } from "react-router-dom"
import { gql } from "urql"
import { useQuery } from "../../../hooks"

import WastedStockChecking from "./WastedStockChecking"
import TruckStockChecking from "./TruckStockChecking"

const SellingPointQuery = gql`
  query ($sellingPointId: ID!) {
    currentSalesDay(sellingPointId: $sellingPointId) {
      id
      salesDayState
    }
  }
`

function SellingPoint() {
  const { sellingPointId } = useParams()
  const history = useHistory()
  const {
    currentSalesDay: { salesDayState },
  } = useQuery(SellingPointQuery, { sellingPointId })
  const redirectTo = `/manager/selling-points/${sellingPointId}`

  switch (salesDayState) {
    case "WastedStockChecking":
      return <WastedStockChecking />
    case "AffectedStockChecking":
      return <TruckStockChecking redirectTo={redirectTo} />
    default:
      history.push(redirectTo)
      return <></>
  }
}

export default SellingPoint
