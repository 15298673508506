import { Typography } from "@material-ui/core"
import { gql } from "urql"
import { useAction } from "../../../hooks"
import TruckForm from "../../../components/admin/TruckForm"

const CreateTruckMutation = gql`
  mutation ($truck: CreateTruckInput!) {
    createTruck(truck: $truck) {
      id
    }
  }
`

function CreateTruck() {
  const createTruck = useAction({
    mutation: CreateTruckMutation,
    successMessage: "Le camion a bien été créé.",
    redirectTo: "/admin/trucks",
  })

  const handleSubmit = async (truck) => {
    await createTruck({ truck })
  }

  return (
    <>
      <Typography variant="h2">Créer un camion</Typography>

      <TruckForm onSubmit={handleSubmit} />
    </>
  )
}

export default CreateTruck
