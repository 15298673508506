import { useState } from "react"
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormGroup,
  IconButton,
} from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import {
  FormikNumericField,
  FormikTextField,
  Checkbox,
  FormikMoneyField,
  Rating,
} from "../../../components/fields"
import DeleteForever from "@material-ui/icons/DeleteForever"
import { produce } from "immer"
import { useParams } from "react-router-dom"
import { gql } from "urql"
import { useAction, useQuery } from "../../../hooks"
import { Formik, Form, FieldArray } from "formik"
import * as Yup from "yup"

const schema = Yup.object({
  comment: Yup.string(),
  oneKgBagAlert: Yup.boolean(),
  twoKgBagAlert: Yup.boolean(),
  weatherQuality: Yup.number(),
  cashFundExpenses: Yup.array().of(
    Yup.object({
      name: Yup.string().required(),
      value: Yup.string().required(),
    })
  ),
  wastedProducts: Yup.array().of(Yup.object()),
})
function CashFundExpenses({ cashFundExpenses }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="contained" sx={{ mt: 2 }}>
        Dépenses caisse
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="cash-fund-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="cash-fund-dialog-title">Dépenses caisse</DialogTitle>

        <FieldArray
          name="cashFundExpenses"
          render={(cashFundExpensesHelper) => (
            <>
              <DialogContent>
                {cashFundExpenses.map(({ name, value }, index) => (
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={5}>
                      <FormikTextField
                        name={`cashFundExpenses[${index}].name`}
                        label="Nom de la dépense"
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <FormikMoneyField
                        name={`cashFundExpenses[${index}].value`}
                        label="Total de la dépense"
                      />
                    </Grid>

                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => cashFundExpensesHelper.remove(index)}
                      >
                        <DeleteForever />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={() =>
                    cashFundExpensesHelper.push({ name: "", value: "" })
                  }
                  variant="contained"
                >
                  Ajouter une nouvelle dépense
                </Button>

                <Button onClick={() => setOpen(false)} variant="contained">
                  Valider
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

function WastedProductChecking({ wastedProducts }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="contained" sx={{ mt: 2 }}>
        Déclarer des pertes
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="wastes-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="wastes-dialog-title">Dépenses caisse</DialogTitle>

        <DialogContent>
          <FieldArray
            name="wastedProducts"
            render={(wastedProductsHelper) => (
              <TableContainer component={Paper}>
                <Table aria-label="wastedProducts">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nom</TableCell>
                      <TableCell>Unité perdue</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {wastedProducts.map(({ fullName }, index) => (
                      <TableRow key={index}>
                        <TableCell>{fullName}</TableCell>
                        <TableCell sx={{ py: 0 }}>
                          <FormikNumericField
                            name={`wastedProducts[${index}].quantity`}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)} variant="contained">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const UnsoldProductsQuery = gql`
  query ($salesDayId: ID!) {
    salesDay(id: $salesDayId) {
      id
      totalProducts {
        product {
          id
          name
          packaging
          fullName
        }
      }
    }
  }
`

const SubmitFeedbackMutation = gql`
  mutation ($feedback: SubmitFeedbackInput!) {
    submitFeedback(feedback: $feedback) {
      id
    }
  }
`

function AdditionalInformation() {
  const { salesDayId } = useParams()
  const {
    salesDay: { totalProducts },
  } = useQuery(UnsoldProductsQuery, {
    salesDayId,
  })

  const submitFeedback = useAction({
    mutation: SubmitFeedbackMutation,
    successMessage: "Les informations ont bien été envoyées.",
  })

  // Wastes
  const unsoldPackagedProducts = totalProducts
    .filter(({ product: { packaging } }) => packaging !== "")
    .map(({ product }) => ({ ...product, quantity: 0 }))

  // Submit
  const handleSubmit = async (data) => {
    const feedback = produce(data, (draft) => {
      draft.cashFundExpenses = draft.cashFundExpenses.map(
        ({ name, value }) => ({
          name,
          value: Math.floor(value * 100),
        })
      )

      draft.wastedProducts = draft.wastedProducts.map(({ id, quantity }) => ({
        id,
        quantity,
      }))

      draft.salesDayId = salesDayId
      draft.weatherQuality = Number(data.weatherQuality)
    })

    await submitFeedback({ feedback })
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Informations complémentaires
      </Typography>

      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          comment: "",
          oneKgBagAlert: false,
          twoKgBagAlert: false,
          weatherQuality: 5,
          cashFundExpenses: [],
          wastedProducts: unsoldPackagedProducts,
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <FormikTextField
              name="comment"
              label="Remarques à faire remonter"
              multiline
              rows={3}
            />

            <FormGroup>
              <Checkbox name="oneKgBagAlert" label="Stock sacs de 1kg faible" />
              <Checkbox name="twoKgBagAlert" label="Stock sacs de 2kg faible" />

              <Typography component="legend">Météo</Typography>
              <Rating
                name="weatherQuality"
                max={10}
                size="large"
                precision={1}
              />

              <CashFundExpenses cashFundExpenses={values.cashFundExpenses} />

              <WastedProductChecking wastedProducts={values.wastedProducts} />
            </FormGroup>

            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              pending={isSubmitting}
            >
              Envoyer les informations
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AdditionalInformation
