import ColorField from "./ColorField"
import { useField } from "formik"

function FormikColorField(props) {
  const [, meta, helpers] = useField(props)

  const { value } = meta
  const { setValue } = helpers

  return <ColorField value={value} onChange={setValue} {...props} />
}

export default FormikColorField
