import { Typography, Card, CardContent } from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { gql } from "urql"
import {
  useQuery,
  useAction,
  useUserRoles,
  useSetCurrentSnackbar,
} from "../../hooks"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { FormikTextField } from "../../components/fields"
import { useAuth } from "reactfire"

const ProfileQuery = gql`
  {
    me {
      id
      email
      firstName
      lastName
    }
  }
`
const UpdateEmailMutation = gql`
  mutation ($email: String!) {
    updateEmail(email: $email) {
      id
    }
  }
`

function Profile() {
  const setCurrentSnackbar = useSetCurrentSnackbar()
  const auth = useAuth()
  const { me } = useQuery(ProfileQuery)
  const roles = useUserRoles()
  const updateEmailMutation = useAction({
    mutation: UpdateEmailMutation,
    successMessage: "L'email a bien été changé",
  })

  async function updateEmail({ email }) {
    try {
      await auth.currentUser.updateEmail(email)
    } catch (error) {
      setCurrentSnackbar({
        severity: "error",
        message: error.message,
      })
      return
    }
    await updateEmailMutation({ email })
  }

  async function updatePassword({ password }, { resetForm }) {
    try {
      await auth.currentUser.updatePassword(password)
      setCurrentSnackbar({
        severity: "success",
        message: "Le mot de passe a bien été changé",
      })
      resetForm()
    } catch (error) {
      setCurrentSnackbar({
        severity: "error",
        message: error.message,
      })
    }
  }

  return (
    <>
      <Typography component="h1" variant="h2" gutterBottom>
        Profil
      </Typography>

      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {me.firstName} {me.lastName}
          </Typography>

          <Formik
            initialValues={{ email: me.email }}
            validationSchema={Yup.object({
              email: Yup.string()
                .required("L'email est requis")
                .email("L'email n'est pas au bon format"),
            })}
            onSubmit={updateEmail}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormikTextField name="email" label="Adresse email" />

                <LoadingButton
                  type="submit"
                  pending={isSubmitting}
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  Enregistrer
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>

      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Modifier le mot de passe
          </Typography>

          <Formik
            initialValues={{ password: "", passwordConfirmation: "" }}
            validationSchema={Yup.object({
              password: Yup.string()
                .required("Le mot de passe est requis")
                .min(8, "Le mot de passe doit faire de 8 caractères")
                .label("Mot de passe"),
              passwordConfirmation: Yup.string()
                .required("Le mot de passe est requis")
                .oneOf(
                  [Yup.ref("password")],
                  "Les mots de passe doivent être pareil"
                ),
            })}
            onSubmit={updatePassword}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormikTextField
                  name="password"
                  label="Mot de passe"
                  type={"password"}
                />
                <FormikTextField
                  name="passwordConfirmation"
                  label="Confirmation"
                  type={"password"}
                />

                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                  pending={isSubmitting}
                >
                  Enregistrer
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>

      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Rôles
          </Typography>

          {roles.map((role) => (
            <Typography key={role.role}>{role.title}</Typography>
          ))}
        </CardContent>
      </Card>
    </>
  )
}

export default Profile
