import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import { useParams } from "react-router-dom"
import { gql } from "urql"
import { useQuery } from "../../hooks"
import PieChartCard from "../../components/manager/PieChartCard"

const SellingPointQuery = gql`
  query ($sellingPointId: ID!) {
    sellingPoint(sellingPoint: { id: $sellingPointId }) {
      id
      name

      currentPeriod {
        startedAt
        commission
        # TODO:
        # total
        card: totalCard
        cash: totalCash
        check: totalCheck
      }

      lastClosedSalesDay {
        date
        commission
        # TODO:
        # total
        card: totalCard
        cash: totalCash
        check: totalCheck
      }

      periods {
        id
        startedAt
        endedAt
        commission
      }
    }
  }
`

function SellingPoint() {
  const { sellingPointId } = useParams()
  const { sellingPoint } = useQuery(SellingPointQuery, { sellingPointId })
  const { lastClosedSalesDay: saleDay, periods } = sellingPoint

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Statistiques
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {saleDay === null ? (
            <Paper
              sx={{
                p: 3,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" align="center">
                Aucun jour de vente enregistré.
              </Typography>
            </Paper>
          ) : (
            <PieChartCard
              title={format(new Date(saleDay.date), "'Journée du ' PPP", {
                locale: fr,
              })}
              values={saleDay}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <PieChartCard
            title="Période actuelle"
            values={sellingPoint.currentPeriod}
          />
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Typography variant="h5" sx={{ mt: 2, ml: 2 }}>
              Historique des périodes
            </Typography>

            <Table aria-label="commissions">
              <TableHead>
                <TableRow>
                  <TableCell>Début</TableCell>
                  <TableCell>Fin</TableCell>
                  <TableCell>Commission</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow
                  sx={{ "&:Last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    {format(
                      new Date(sellingPoint.currentPeriod.startedAt),
                      "PPP",
                      { locale: fr }
                    )}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    {sellingPoint.currentPeriod.commission / 100} €
                  </TableCell>
                </TableRow>

                {periods.map((period) => (
                  <TableRow
                    key={period.id}
                    sx={{ "&:Last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      {format(new Date(period.startedAt), "PPP", {
                        locale: fr,
                      })}
                    </TableCell>
                    <TableCell>
                      {format(new Date(period.endedAt), "PPP", { locale: fr })}
                    </TableCell>
                    <TableCell>{period.commission / 100} €</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}

export default SellingPoint
