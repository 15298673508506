import { useState, useEffect } from "react"
import { TextField, MenuItem } from "@material-ui/core"
import { useParams, useHistory } from "react-router-dom"
import ConfirmationDialog from "./ConfirmationDialog"

const weekDays = [
  {
    label: "Lundi",
    value: "monday",
  },
  {
    label: "Mardi",
    value: "tuesday",
  },
  {
    label: "Mercredi",
    value: "wednesday",
  },
  {
    label: "Jeudi",
    value: "thursday",
  },
  {
    label: "Vendredi",
    value: "friday",
  },
  {
    label: "Samedi",
    value: "saturday",
  },
  {
    label: "Dimanche",
    value: "sunday",
  },
]

function WeekDaysSelect({ mustConfirm, ...props }) {
  const history = useHistory()
  const { weekDay } = useParams()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedWeekDay, setSelectedWeekDay] = useState(weekDay)

  useEffect(() => {
    setSelectedWeekDay(weekDay)
  }, [weekDay])

  const updateWeekDay = (weekDay) => {
    history.push(`/admin/logistic/delivery-days/${weekDay}`)
  }

  const handleDialogConfirm = async () => {
    updateWeekDay(selectedWeekDay)
    setDialogOpen(false)
  }

  const handleChange = (event) => {
    const weekDay = event.target.value

    if (mustConfirm) {
      setSelectedWeekDay(weekDay)
      setDialogOpen(true)
    } else {
      updateWeekDay(weekDay)
    }
  }

  return (
    <>
      <TextField
        label="Jour de la semaine"
        select
        onChange={handleChange}
        value={weekDay}
        {...props}
      >
        {weekDays.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>

      <ConfirmationDialog
        open={dialogOpen}
        onCancel={() => setDialogOpen(false)}
        onConfirm={handleDialogConfirm}
        text={
          "Les changements n'ont pas été enregistrés, voulez vous vraiment continuer ?"
        }
      />
    </>
  )
}

export default WeekDaysSelect
