import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"
import FormikNumericField from "./FormikNumericField"
import { coinUnits, banknoteUnits } from "../../utils"

function FormikCashFundDetailsDialog({ name, open, onClose, totalCashFund }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="cash-fund-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="cash-fund-dialog-title">Aide à la saisie</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {banknoteUnits.map(({ label, value: unitValue }) => (
              <FormikNumericField
                name={`${name}[${unitValue}]`}
                key={label}
                label={label}
              />
            ))}
          </Grid>

          <Grid item xs={12} md={6}>
            {coinUnits.map(({ label, value: unitValue }) => (
              <FormikNumericField
                name={`${name}[${unitValue}]`}
                key={label}
                label={label}
              />
            ))}
          </Grid>
        </Grid>

        <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
          Total : {totalCashFund} €
        </Typography>
      </DialogContent>

      <DialogActions>
        {/* <Button onClick={onClose}>Annuler</Button> */}

        <Button variant="contained" onClick={onClose}>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FormikCashFundDetailsDialog
