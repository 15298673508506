import { Grid } from "@material-ui/core"

function InnerLayout({ children, xs }) {
  return (
    <Grid
      container
      sx={{ textAlign: "center", flexGrow: 1 }}
      alignContent="center"
      justifyContent="center"
    >
      <Grid item xs={xs}>
        {children}
      </Grid>
    </Grid>
  )
}

export default InnerLayout
