import { TextField } from "@material-ui/core"

function ContainerInnerField({ ...props }) {
  return (
    <TextField
      disabled
      inputProps={{
        pattern: "[1-9]^[0-9]*",
        style: { textAlign: "center" },
      }}
      {...props}
    />
  )
}

export default ContainerInnerField
