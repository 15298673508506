import { useHistory } from "react-router-dom"
import { gql } from "urql"
import { useQuery, useAction } from "../../hooks"
import { Grid, Card, CardContent, Typography } from "@material-ui/core"
import { format } from "date-fns"
import { fr } from "date-fns/locale"

const SellingPointsQuery = gql`
  {
    me {
      sellerAt {
        id
        name
        canCreateSalesDay
        currentSalesDay {
          id
        }
      }
    }
    unclosedSellerSalesDay {
      id
      date
      salesDayStateLabel
      sellingPoint {
        id
        name
      }
    }
  }
`

const CreateNewSellingPointSalesDayMutation = gql`
  mutation ($sellingPointId: ID!) {
    createNewSellingPointSalesDay(sellingPointId: $sellingPointId) {
      id
    }
  }
`

function SellingPoints() {
  const history = useHistory()
  const {
    me: { sellerAt: sellingPoints },
    unclosedSellerSalesDay,
  } = useQuery(SellingPointsQuery)

  const createNewSellingPointSalesDayMutation = useAction({
    mutation: CreateNewSellingPointSalesDayMutation,
    successMessage: "Une nouvelle journée de vente a bien éte commencée.",
  })

  async function createNewSellingPointSalesDay(sellingPoint) {
    if (sellingPoint?.currentSalesDay?.id) {
      redirectToSellingPointSalesDay(
        sellingPoint.id,
        sellingPoint.currentSalesDay.id
      )
      return
    }
    const {
      data: {
        createNewSellingPointSalesDay: { id: salesDayId },
      },
    } = await createNewSellingPointSalesDayMutation({
      sellingPointId: sellingPoint.id,
    })

    redirectToSellingPointSalesDay(sellingPoint.id, salesDayId)
  }

  function redirectToSellingPointSalesDay(sellingPointId, salesDayId) {
    history.push(`/seller/selling-points/${sellingPointId}/${salesDayId}`)
  }

  return (
    <>
      <Typography variant="h4">
        Commencer une nouvelle journée de vente
      </Typography>

      <Grid container spacing={2} style={{ marginTop: 10 }}>
        {sellingPoints
          .filter(({ canCreateSalesDay }) => canCreateSalesDay)
          .map((sellingPoint) => (
            <Grid key={sellingPoint.id} item xs={12} sm={6} md={4}>
              <Card
                item
                onClick={() => createNewSellingPointSalesDay(sellingPoint)}
                style={{ cursor: "pointer", marginTop: 15, padding: 10 }}
              >
                <CardContent>
                  <Typography variant="h5">{sellingPoint.name}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>

      <Typography variant="h4" sx={{ mt: 5 }}>
        Continuer une journée de vente
      </Typography>

      <Grid container spacing={2} style={{ marginTop: 10 }}>
        {unclosedSellerSalesDay.map((salesDay) => (
          <Grid key={salesDay.id} item xs={12} sm={6} md={4}>
            <Card
              item
              onClick={() =>
                redirectToSellingPointSalesDay(
                  salesDay.sellingPoint.id,
                  salesDay.id
                )
              }
              style={{ cursor: "pointer", marginTop: 15, padding: 10 }}
            >
              <CardContent>
                <Typography variant="h5">
                  {salesDay.sellingPoint.name}
                </Typography>
                <Typography variant="h6">
                  {format(new Date(salesDay.date), "'Le' PPP", {
                    locale: fr,
                  })}
                </Typography>
                <Typography>{salesDay.salesDayStateLabel}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default SellingPoints
