import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { Formik, Form } from "formik"
import { useParams } from "react-router-dom"
import { gql } from "urql"
import { useQuery, useAction } from "../../../../../hooks"
import {
  FormikNumericField,
  FormikWeightField,
} from "../../../../../components/fields"

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

const Query = gql`
  query ($sellingPointId: ID!) {
    sellingPoint(sellingPoint: { id: $sellingPointId }) {
      items {
        quantity
        weight

        product {
          id
        }
      }
    }

    products {
      id
      fullName
      packaging
    }
  }
`

const UpdateSellingPointItemsMutation = gql`
  mutation ($items: UpdateSellingPointItemsInput!) {
    updateSellingPointItems(items: $items) {
      id
    }
  }
`

function UpdateSellingPointItemsView() {
  const { sellingPointId } = useParams()
  const {
    sellingPoint: { items },
    products,
  } = useQuery(Query, { sellingPointId })
  const updateSellingPointItems = useAction({
    mutation: UpdateSellingPointItemsMutation,
    successMessage: "Les invendus ont bien été mis à jour.",
    redirectTo: "/admin/selling-points",
  })

  const itemByProductId = {}
  for (const item of items) {
    itemByProductId[item.product.id] = item
  }

  const initialValues = {
    items: products.map((product) => {
      const item = itemByProductId[product.id]

      if (item) {
        return {
          productId: product.id,
          quantity: item.quantity,
          weight: item.weight,
        }
      }

      return {
        productId: product.id,
        quantity: product.packaging === "" ? null : 0,
        weight: product.packaging === "" ? 0 : null,
      }
    }),
  }

  const handleSubmit = async ({ items }) => {
    await updateSellingPointItems({
      items: {
        sellingPointId,
        items: items.map((item) => ({
          productId: item.productId,
          quantity: item.quantity,
          weight: item.weight === null ? null : parseFloat(item.weight),
        })),
      },
    })
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Éditer les invendus
      </Typography>

      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ isSubmitting }) => (
          <Form>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Produit</TableCell>
                    <TableCell>Quantité / Poids</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {products.map((product, index) => (
                    <TableRow key={product.id} sx={hideLastBorder}>
                      <TableCell>{product.fullName}</TableCell>
                      <TableCell sx={{ py: 0 }}>
                        {product.packaging === "" ? (
                          <FormikWeightField name={`items[${index}].weight`} />
                        ) : (
                          <FormikNumericField
                            name={`items[${index}].quantity`}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <LoadingButton
              variant="contained"
              sx={{ mt: 2 }}
              pending={isSubmitting}
              type="submit"
            >
              Enregistrer
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default UpdateSellingPointItemsView
