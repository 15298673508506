import { useState } from "react"
import {
  Grid,
  Card,
  CardContent,
  Chip,
  Menu,
  MenuItem,
  Button,
  IconButton,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { useSetCurrentSnackbar } from "../../../hooks"

const newSellerCardStyle = {
  minHeight: 80,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

function NumberOfProductInSellerProductCartChip({ count }) {
  if (!count) {
    return null
  }

  return <Chip label={count} color="primary" />
}

function NewSellerCard({ onClick, selectedSellersIds, sellers }) {
  const newSellers = sellers.filter(
    ({ id }) => !selectedSellersIds.includes(id)
  )

  const [newSellerAnchorEl, setNewSellerAnchorEl] = useState(null)
  const openMenu = (event) => {
    setNewSellerAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setNewSellerAnchorEl(null)
  }

  function handleClick(newSeller) {
    closeMenu()
    onClick(newSeller)
  }

  if (newSellers.length === 0) {
    return null
  }

  return (
    <Grid item xs={6} sm={4}>
      <Card sx={newSellerCardStyle}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={openMenu}
        >
          Ajouter un vendeur
        </Button>

        <Menu
          id="new-seller-menu"
          anchorEl={newSellerAnchorEl}
          keepMounted
          open={Boolean(newSellerAnchorEl)}
          onClose={closeMenu}
        >
          {newSellers.map((newSeller) => (
            <MenuItem
              key={newSeller.firstName}
              onClick={() => handleClick(newSeller)}
            >
              {newSeller.fullName}
            </MenuItem>
          ))}
        </Menu>
      </Card>
    </Grid>
  )
}

function SellerSelect({
  onSellerCardClick,
  selectedSellers,
  addSellerToSellers,
  removeSeller,
  sellers,
}) {
  const setCurrentSnackbar = useSetCurrentSnackbar()

  const selectedSellersIds = Object.keys(selectedSellers)

  function addSeller(newSeller) {
    if (newSeller.id in selectedSellers) {
      setCurrentSnackbar({
        severity: "warning",
        message: "Ce vendeur a déjà été ajouté.",
      })
      return
    }

    addSellerToSellers(newSeller.id)
  }

  function handleSellerCardClick(id) {
    onSellerCardClick(id)
  }

  function numberOfProductInSellerProductCart(sellerId) {
    return selectedSellers[sellerId].products.reduce(
      (total, { quantity, weight }) => (weight ? total + 1 : total + quantity),
      0
    )
  }

  return (
    <Grid container spacing={2} alignItems="center">
      {selectedSellersIds.map((id, index) => {
        const { fullName } = sellers.find((seller) => seller.id === id)

        return (
          <Grid item key={id} xs={12} sm={4}>
            <Card
              onClick={() => handleSellerCardClick(id)}
              style={{ cursor: "pointer" }}
            >
              <CardContent>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item style={{ padding: "12px" }}>
                    {fullName}
                  </Grid>

                  <Grid item>
                    <NumberOfProductInSellerProductCartChip
                      count={numberOfProductInSellerProductCart(id)}
                    />
                    {!!index && (
                      <IconButton onClick={() => removeSeller(id)}>
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )
      })}

      <NewSellerCard
        onClick={addSeller}
        selectedSellersIds={selectedSellersIds}
        sellers={sellers}
      />
    </Grid>
  )
}

export default SellerSelect
