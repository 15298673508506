import { Checkbox, FormControlLabel } from "@material-ui/core"

import {} from "@material-ui/core"
import { useField } from "formik"

function FormikCheckbox({ helperText, ...props }) {
  const [field, { error, touched }] = useField(props)

  const hasError = touched && Boolean(error)

  return (
    <FormControlLabel
      label={props.label}
      control={
        <Checkbox
          {...props}
          {...field}
          error={hasError}
          helperText={hasError ? error : helperText}
        />
      }
    />
  )
}

export default FormikCheckbox
