import {
  MenuItem,
  Paper,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import { LoadingButton } from "@material-ui/lab"
import { gql } from "urql"
import { Formik, Form, FieldArray } from "formik"
import * as Yup from "yup"
import { useQuery } from "../../hooks"
import { FormikTextField, FormikAutocomplete } from "../fields"
import { format } from "../../utils"
import { produce } from "immer"

Yup.setLocale({
  mixed: {
    notType: "Veuillez saisir un nombre",
  },
})

function encodePrice(price) {
  return Math.round(parseFloat(price) * 100)
}

const validationSchema = Yup.object({
  name: Yup.string().required("Veuillez saisir un nom"),
  type: Yup.string().required("Veuillez choisir un type"),
  priceCategory: Yup.string().required("Veuillez choisir une classe de prix"),
  managers: Yup.array().required(),
  sellers: Yup.array().required(),
  nextSellingPointId: Yup.string(),
  offers: Yup.array()
    .of(
      Yup.object({
        productId: Yup.string()
          .nullable()
          .required("Veuillez choisir un produit"),
        quantity: Yup.number("Veuillez saisir un nombre")
          .required("Veuillez saisir une quantité")
          .min(0, "Veuillez saisir un nombre positif")
          .integer("Veuillez saisir un nombre entier"),
        offer: Yup.number("Veuillez saisir un nombre")
          .required("Veuillez saisir une offre")
          .min(0, "Veuillez saisir un nombre positif")
      })
    )
    .required(),
  priceCategoryExceptions: Yup.array()
    .of(
      Yup.object({
        productId: Yup.string()
          .nullable()
          .required("Veuillez choisir un produit"),
        priceCategory: Yup.string().required(
          "Veuillez choisir une classe de prix"
        ),
      })
    )
    .required(),
  commissionRate: Yup.number("Veuillez saisir un nombre")
    .required("Veuillez saisir un taux de commission")
    .min(0, "Veuillez saisir un nombre positif")
    .integer("Veuillez saisir un nombre entier")
    .max(100, "Veuillez saisir un nombre plus petit ou égal à 100"),
})

const Query = gql`
  {
    users {
      id
      firstName
      lastName
      email
    }

    products {
      id
      name
      variety
      packaging
      prices {
        first
        second
        third
      }
    }

    sellingPoints {
      id
      name
    }
  }
`

function SellingPointForm({ sellingPoint, onSubmit }) {
  const { users, products, sellingPoints } = useQuery(Query)

  const handleSubmit = async (data) => {
    await onSubmit(produce(data, (draft) => {
      if (draft.type !== "STAND") {
        draft.sellers = []
      }

      draft.commissionRate = parseInt(draft.commissionRate)

      for (const offer of draft.offers) {
        offer.quantity = parseInt(offer.quantity)
        offer.offer = encodePrice(offer.offer)
      }

      if (draft.nextSellingPointId === "") {
        draft.nextSellingPointId = null
      }
    }))
  }

  const getOptionLabel = (userId) => {
    const user = users.find((user) => user.id === userId)

    if (!user) {
      return ""
    }

    return `${user.firstName} ${user.lastName} (${user.email})`
  }

  const userIds = users.map((user) => user.id)

  function getProductPriceExceptionLabel(productId, priceCategory) {
    if (!productId) {
      return ""
    }
    const { prices } = products.find(({ id }) => productId === id)
    return ` ${format.price(prices[priceCategory])}€`
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={
        sellingPoint ?? {
          name: "",
          type: "",
          priceCategory: "",
          commissionRate: 25,
          managers: [],
          sellers: [],
          nextSellingPointId: "",
          offers: [],
          priceCategoryExceptions: [],
        }
      }
      validationSchema={validationSchema}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <FormikTextField name="name" label="Nom" />

          <FormikTextField name="type" label="Type" select dataTestId="type">
            <MenuItem value="STORE">Magasin</MenuItem>
            <MenuItem value="STAND">Stand</MenuItem>
          </FormikTextField>

          <FormikTextField
            name="priceCategory"
            label="Classe de prix"
            select
            dataTestId="priceCategory"
          >
            <MenuItem value="FIRST">Première classe</MenuItem>
            <MenuItem value="SECOND">Deuxième classe</MenuItem>
            <MenuItem value="THIRD">Troisième classe</MenuItem>
          </FormikTextField>

          <FormikTextField name="commissionRate" label="Taux de commission" />

          <FormikAutocomplete
            name="managers"
            label="Responsables"
            multiple
            options={userIds}
            getOptionLabel={getOptionLabel}
            dataTestId="managers"
          />

          {values.type === "STAND" && (
            <>
              <FormikAutocomplete
                name="sellers"
                dataTestId="sellers"
                label="Vendeurs"
                multiple
                options={userIds}
                getOptionLabel={getOptionLabel}
              />
              <FormikTextField
                name="nextSellingPointId"
                dataTestId="nextSellingPointId"
                label="Redirection des invendus vers"
                select
                clearable
              >
                <MenuItem value="">
                  <em>Sur place</em>
                </MenuItem>

                {sellingPoints
                  .filter(({ id }) => id !== sellingPoint?.id)
                  .map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
              </FormikTextField>
            </>
          )}

          <TableContainer component={Paper} sx={{ py: 2 }}>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ mx: 2, color: "text.secondary" }}
            >
              Promotions
            </Typography>

            <FieldArray
              name="offers"
              render={(arrayHelpers) => {
                const filteredProducts = products.filter(
                  (product) =>
                    !values.offers.find(
                      (offer) => offer.productId === product.id
                    )
                )

                return (
                  <>
                    <Table aria-label="products">
                      <TableHead>
                        <TableRow>
                          <TableCell>Produit</TableCell>
                          <TableCell>Quantité</TableCell>
                          <TableCell>Offre</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {values.offers.map(
                          ({ productId, quantity, offer }, index) => (
                            <TableRow key={index}>
                              <TableCell sx={{ py: 0, pr: 1 }}>
                                <FormikAutocomplete
                                  name={`offers[${index}].productId`}
                                  dataTestId={`offers[${index}].productId`}
                                  label="Produit"
                                  options={filteredProducts.map(
                                    (product) => product.id
                                  )}
                                  getOptionLabel={(productId) => {
                                    const product = products.find(
                                      (product) => product.id === productId
                                    )

                                    if (!product) {
                                      return ""
                                    }

                                    let name = product.name
                                    if (product.variety) {
                                      name += ` - ${product.variety}`
                                    }
                                    if (product.packaging) {
                                      name += ` (${product.packaging})`
                                    }

                                    return name
                                  }}
                                />
                              </TableCell>

                              <TableCell sx={{ py: 0 }}>
                                <FormikTextField
                                  name={`offers[${index}].quantity`}
                                  dataTestId={`offers[${index}].quantity`}
                                  label="Quantité"
                                >
                                </FormikTextField>
                              </TableCell>

                              <TableCell sx={{ py: 0, pl: 1 }}>
                                <FormikTextField
                                  name={`offers[${index}].offer`}
                                  dataTestId={`offers[${index}].offer`}
                                  label="Promotion"
                                >
                                </FormikTextField>
                              </TableCell>

                              <TableCell sx={{ py: 0 }}>
                                <IconButton
                                  size="small"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>

                    <Button
                      sx={{ m: 2 }}
                      variant="contained"
                      type="button"
                      disableElevation
                      onClick={() =>
                        arrayHelpers.push({
                          productId: null,
                          quantity: 0,
                          offer: 0
                        })
                      }
                    >
                      Ajouter une promotion
                    </Button>
                  </>
                )
              }}
            />
          </TableContainer>

          <TableContainer component={Paper} sx={{ pt: 2, mt: 2 }}>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ mx: 2, color: "text.secondary" }}
            >
              Exceptions de la classe de prix
            </Typography>

            <FieldArray
              name="priceCategoryExceptions"
              render={(arrayHelpers) => {
                const filteredProducts = products.filter(
                  (product) =>
                    !values.priceCategoryExceptions.find(
                      (exception) => exception.productId === product.id
                    )
                )

                return (
                  <>
                    <Table aria-label="products">
                      <TableHead>
                        <TableRow>
                          <TableCell>Produit</TableCell>
                          <TableCell>Classe de prix</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {values.priceCategoryExceptions.map(
                          ({ productId, priceCategory }, index) => (
                            <TableRow key={index}>
                              <TableCell sx={{ py: 0, pr: 1 }}>
                                <FormikAutocomplete
                                  name={`priceCategoryExceptions[${index}].productId`}
                                  dataTestId={`priceCategoryExceptions[${index}].productId`}
                                  label="Produit"
                                  options={filteredProducts.map(
                                    (product) => product.id
                                  )}
                                  getOptionLabel={(productId) => {
                                    const product = products.find(
                                      (product) => product.id === productId
                                    )

                                    if (!product) {
                                      return ""
                                    }

                                    let name = product.name
                                    if (product.variety) {
                                      name += ` - ${product.variety}`
                                    }
                                    if (product.packaging) {
                                      name += ` (${product.packaging})`
                                    }

                                    return name
                                  }}
                                />
                              </TableCell>

                              <TableCell sx={{ py: 0, pl: 1 }}>
                                <FormikTextField
                                  name={`priceCategoryExceptions[${index}].priceCategory`}
                                  dataTestId={`priceCategoryExceptions[${index}].priceCategory`}
                                  label="Classe de prix"
                                  select
                                >
                                  <MenuItem value="FIRST">
                                    Première classe
                                    {getProductPriceExceptionLabel(
                                    values.priceCategoryExceptions[index]
                                      .productId,
                                    "first"
                                  )}
                                  </MenuItem>
                                  <MenuItem value="SECOND">
                                    Deuxième classe
                                    {getProductPriceExceptionLabel(
                                    values.priceCategoryExceptions[index]
                                      .productId,
                                    "second"
                                  )}
                                  </MenuItem>
                                  <MenuItem value="THIRD">
                                    Troisième classe
                                    {getProductPriceExceptionLabel(
                                    values.priceCategoryExceptions[index]
                                      .productId,
                                    "third"
                                  )}
                                  </MenuItem>
                                </FormikTextField>
                              </TableCell>

                              <TableCell sx={{ py: 0 }}>
                                <IconButton
                                  size="small"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>

                    <Button
                      sx={{ m: 2 }}
                      variant="contained"
                      type="button"
                      disableElevation
                      onClick={() =>
                        arrayHelpers.push({
                          productId: null,
                          priceCategory: "",
                        })
                      }
                    >
                      Ajouter une exception
                    </Button>
                  </>
                )
              }}
            />
          </TableContainer>

          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
            pending={isSubmitting}
            data-testid="submit"
          >
            {sellingPoint ? "Éditer" : "Créer"}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  )
}

export default SellingPointForm
