import { IconButton, MenuItem } from "@material-ui/core"
import { useAuth } from "reactfire"
import { useHistory } from "react-router-dom"
import { useSetCurrentSnackbar } from "../hooks"
import { ExitToApp } from "@material-ui/icons"

function SignOutButton(props) {
  const auth = useAuth()
  const history = useHistory()
  const setCurrentSnackbar = useSetCurrentSnackbar()

  const handleClick = async () => {
    try {
      await auth.signOut()

      history.push("/auth/sign-in")

      setCurrentSnackbar({
        severity: "success",
        message: "Vous êtes bien déconnecté.",
      })
    } catch (error) {
      setCurrentSnackbar({
        severity: "error",
        message: "Une erreur est survenue, veuillez réessayer.",
      })
    }
  }
  if (props.label) {
    return (
      <MenuItem onClick={handleClick} id="signOut">
        {props.label}
      </MenuItem>
    )
  }

  return (
    <IconButton onClick={handleClick} {...props} id="signOut">
      <ExitToApp />
    </IconButton>
  )
}

export default SignOutButton
