import { Rating } from "@material-ui/core"

import {} from "@material-ui/core"
import { useField } from "formik"

function FormikRating({ helperText, ...props }) {
  const [field, { error, touched }] = useField(props)

  const hasError = touched && Boolean(error)

  return (
    <Rating
      {...props}
      {...field}
      error={hasError}
      helperText={hasError ? error : helperText}
    />
  )
}

export default FormikRating
