import { Typography } from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { Formik, Form } from "formik"
import { FormikCashFundField } from "../../../components/fields"
import { useParams } from "react-router-dom"
import { gql } from "urql"
import { useAction } from "../../../hooks"

const CashFundCheckingMutation = gql`
  mutation ($salesDayId: ID!, $cashFund: Int!) {
    cashFundChecking(salesDayId: $salesDayId, cashFund: $cashFund) {
      id
    }
  }
`

function CashFundCheckingForm() {
  const { salesDayId } = useParams()
  const cashFundCheckingMutation = useAction({
    mutation: CashFundCheckingMutation,
    successMessage: "Le fond de caisse a bien été enregistré.",
  })

  const handleSubmit = async (data) => {
    await cashFundCheckingMutation({
      salesDayId,
      cashFund: Math.floor(data.cashFund * 100),
    })
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={{ cashFund: "" }}>
      {({ isSubmitting }) => (
        <Form>
          <FormikCashFundField name="cashFund" label="Fond de caisse" />

          <LoadingButton
            pending={isSubmitting}
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
          >
            Valider
          </LoadingButton>
        </Form>
      )}
    </Formik>
  )
}

function CashFundChecking() {
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Fond de caisse
      </Typography>

      <CashFundCheckingForm />
    </>
  )
}

export default CashFundChecking
