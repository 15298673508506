import { Typography, Grid, Card, CardContent } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { format } from "../utils"

const useStyles = makeStyles({
  container: {
    height: window.innerHeight * 0.8 - 50,
    overflow: "auto",
  },
  productCard: {
    cursor: "pointer",
  },
})

function ProductCard({
  product: { id, name, variety, packaging, price, color },
  onClick,
}) {
  const classes = useStyles()

  const handleClick = () => {
    if (onClick) {
      onClick(id)
    }
  }

  return (
    <Card
      onClick={handleClick}
      className={classes.productCard}
      style={{
        backgroundColor: color,
      }}
    >
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          style={{ minHeight: 85 }}
        >
          <Grid item xs={8}>
            <Typography variant="subtitle1" fontWeight="bold" component="div">
              {name} {variety}
            </Typography>

            <Typography variant="subtitle1" component="p">
              {packaging}
            </Typography>

            {!!price && (
              <Typography variant="subtitle1" component="p">
                {format.price(price)}€
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ProductCard
