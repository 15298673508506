import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
} from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { Formik, Form } from "formik"
import {
  FormikNumericField,
  FormikWeightField,
} from "../../../../components/fields"
import { useQuery, useAction } from "../../../../hooks"
import { gql } from "urql"
import { useParams } from "react-router-dom"
import { format, addDays } from "date-fns"
import { fr } from "date-fns/locale"
import { useState } from "react"

const ForecastsQuery = gql`
  query ($sellingPointId: ID!, $forecastDay: Date!) {
    sellingPoint(sellingPoint: { id: $sellingPointId }) {
      id
      name
    }
    forecasts(sellingPointId: $sellingPointId, forecastDay: $forecastDay) {
      product {
        id
      }
      need
      sold
    }
    products: visibleProducts {
      id
      fullName
      packaging
      unitsPerContainer
      containerCapacity
    }
  }
`

const UpdateForecastsMutation = gql`
  mutation (
    $sellingPointId: ID!
    $forecastDay: Date!
    $forecasts: [ForecastInput!]!
  ) {
    updateForecasts(
      sellingPointId: $sellingPointId
      forecastDay: $forecastDay
      forecasts: $forecasts
    ) {
      product {
        id
      }
      need
    }
  }
`

const forecastDays = Array(7)
  .fill(0)
  .map((_, index) => addDays(new Date(), index))

function Forecasts() {
  const { sellingPointId } = useParams()

  const [currentForecastDayIndex, setCurrentForecastDayIndex] = useState(0)

  const {
    sellingPoint,
    products,
    forecasts: forecastsAsArray,
  } = useQuery(ForecastsQuery, {
    sellingPointId,
    forecastDay: forecastDays[currentForecastDayIndex],
  })

  const forecasts = Object.fromEntries(
    forecastsAsArray.map(({ product, need, sold }) => [
      product.id,
      {
        product,
        need,
        sold,
      },
    ])
  )

  const updateForecasts = useAction({
    mutation: UpdateForecastsMutation,
    successMessage: "Les prévisions ont bien été mises à jour",
    redirectTo: `/admin/logistic/forecasts`,
  })

  const handleSubmit = async ({ forecasts }) => {
    await updateForecasts({
      sellingPointId,
      forecastDay: forecastDays[currentForecastDayIndex],
      forecasts,
    })
  }

  const initialValues = {
    forecasts: products.map(({ id }) => {
      return {
        product: id,
        need: forecasts[id]?.need ?? 0,
      }
    }),
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        {sellingPoint.name}
      </Typography>

      <TextField
        select
        label="Date"
        value={currentForecastDayIndex}
        onChange={(event) => setCurrentForecastDayIndex(event.target.value)}
      >
        {forecastDays.map((forecastDay, index) => (
          <MenuItem key={index} value={index}>
            {format(forecastDay, "'Le' PPP", {
              locale: fr,
            })}
          </MenuItem>
        ))}
      </TextField>

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ values, isSubmitting }) => (
          <Form>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Vente S - 1 (détails)</TableCell>
                    <TableCell>Prévision (détails)</TableCell>
                    <TableCell>Plateaux</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {products.map((product, index) => {
                    return (
                      <TableRow key={product.id}>
                        <TableCell>{product.fullName}</TableCell>
                        <TableCell>
                          {forecasts[product.id]?.sold ?? 0}
                        </TableCell>
                        <TableCell sx={{ py: 0 }}>
                          {product.packaging ? (
                            <FormikNumericField
                              name={`forecasts[${index}].need`}
                            />
                          ) : (
                            <FormikWeightField
                              name={`forecasts[${index}].need`}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {`${Math.round(
                            (values.forecasts[index].need ?? 0) /
                              (product.unitsPerContainer *
                                (product.containerCapacity ?? 1))
                          )} de ${Math.round(
                            product.unitsPerContainer *
                              (product.containerCapacity ?? 1)
                          )} ${product.packaging ? "barquettes" : "kg"}`}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              pending={isSubmitting}
            >
              Enregistrer
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Forecasts
