import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { gql } from "urql"
import { useQuery } from "../../../../hooks"

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

const SellingPointsQuery = gql`
  query {
    sellingPoints {
      id
      name
    }
  }
`

function Forecasts() {
  const history = useHistory()
  const { sellingPoints } = useQuery(SellingPointsQuery)

  const handleClick = ({ sellingPointId }) => {
    history.push(`/admin/logistic/forecasts/${sellingPointId}`)
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Prévisions
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="Points de ventes">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sellingPoints.map(({ id, name }) => (
              <TableRow key={id} sx={hideLastBorder}>
                <TableCell>{name}</TableCell>

                <TableCell sx={{ py: 0 }}>
                  <Button
                    onClick={() =>
                      handleClick({
                        sellingPointId: id,
                      })
                    }
                    variant="contained"
                  >
                    Gérer les prévisions
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Forecasts
