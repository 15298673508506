import { Typography } from "@material-ui/core"
import { format } from "date-fns"
import { fr } from "date-fns/locale"

const DistributionTitleWithDate = () => (
  <Typography variant="h2" gutterBottom>
    {`Répartition des stocks pour demain: ${format(
      Date.now() + 1 * 24 * 60 * 60 * 1000,
      " d MMMM y",
      {
        locale: fr,
      }
    )}`}
  </Typography>
)

export default DistributionTitleWithDate
