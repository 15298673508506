import { useState } from "react"
import {
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Grid,
  Card,
  CardContent,
  CardActions,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { useQuery } from "../../../hooks"
import { gql } from "urql"
import { format } from "date-fns"

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

function ProductsDialog({ salesDay }) {
  const [open, setOpen] = useState(false)
  const unsoldStockHasBeenChecked =
    salesDay.salesDayState !== "CashFundChecking" &&
    salesDay.salesDayState !== "CashRegister"

  return (
    <>
      <Button onClick={() => setOpen(true)}>Voir les produits</Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Produits</DialogTitle>

        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Produit</TableCell>
                  <TableCell>Livrés</TableCell>
                  <TableCell>Vendus</TableCell>
                  <TableCell>Reste (Théorique)</TableCell>
                  {unsoldStockHasBeenChecked && <TableCell>Reste</TableCell>}
                </TableRow>
              </TableHead>

              <TableBody>
                {salesDay.totalProducts.map(
                  ({
                    product: { id, fullName },
                    price,
                    delivered,
                    theoricalUnsold,
                    unsold,
                    sold,
                    totalEarned,
                  }) => (
                    <TableRow key={id} sx={hideLastBorder}>
                      <TableCell component="th" scope="row">
                        {`${fullName} - ( ${price / 100}€ )`}
                      </TableCell>

                      <TableCell>{delivered}</TableCell>
                      <TableCell>{`${sold} ( ${
                        totalEarned / 100
                      }€ )`}</TableCell>
                      <TableCell>{theoricalUnsold}</TableCell>
                      {unsoldStockHasBeenChecked && (
                        <TableCell>{unsold}</TableCell>
                      )}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function SalesDayCard({ salesDay }) {
  const startedAt = format(
    new Date(salesDay.sellerCashFundSubmittedAt),
    "HH:mm"
  )
  let endedAt = null
  if (salesDay.unsoldStockCheckedAt) {
    endedAt = format(new Date(salesDay.unsoldStockCheckedAt), "HH:mm")
  }

  return (
    <Card>
      <CardContent>
        <Grid container wrap="nowrap">
          <Grid item sx={{ flexGrow: 1 }}>
            <Typography variant="h5" gutterBottom>
              {salesDay.sellingPoint.name}
            </Typography>
          </Grid>

          <Grid item>
            <Chip
              sx={{ ml: 1 }}
              color="primary"
              label={salesDay.salesDayStateLabel}
            />
          </Grid>
        </Grid>

        <Typography>
          Journée commencée à <Chip size="small" label={startedAt} />{" "}
          {endedAt && (
            <>
              et terminée à <Chip size="small" label={endedAt} />
            </>
          )}
        </Typography>
        {salesDay.closedBy && (
          <>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Responsable
            </Typography>

            <Typography>{salesDay.closedBy.fullName}</Typography>
          </>
        )}

        {salesDay.sellers.length > 0 && (
          <>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Vendeurs
            </Typography>

            <Typography>
              {salesDay.sellers.map(({ fullName }) => fullName).join(", ")}
            </Typography>
          </>
        )}

        <Typography variant="h6" sx={{ mt: 1 }}>
          Chiffre d'affaire
        </Typography>

        <Typography>{salesDay.turnover / 100} €</Typography>
      </CardContent>

      <CardActions>
        <ProductsDialog salesDay={salesDay} />
      </CardActions>
    </Card>
  )
}

const currentSalesDayQuery = gql`
  {
    me {
      managerOf {
        id
        name
        canCreateSalesDay
        currentSalesDay {
          id
          turnover
          salesDayState
          salesDayStateLabel
          sellingPoint {
            id
            name
          }
          sellers {
            id
            fullName
          }
          closedBy {
            id
            fullName
          }
          totalProducts {
            product {
              name
              fullName
            }
            price
            delivered
            theoricalUnsold
            unsold
            sold
            totalEarned
          }
          sellerCashFundSubmittedAt
          salesClosedAt
          unsoldStockCheckedAt
          closedAt
        }
      }
    }
  }
`

function CurrentSalesDaysDashboard() {
  const {
    me: { managerOf },
  } = useQuery(currentSalesDayQuery)

  return (
    <>
      <Typography variant="h2">Ventes en cours</Typography>

      <Grid container spacing={3}>
        {managerOf
          .filter(({ currentSalesDay }) => currentSalesDay)
          .map(({ name, currentSalesDay }) => (
            <Grid key={name} item md={6} xs={12}>
              <SalesDayCard salesDay={currentSalesDay} />
            </Grid>
          ))}
      </Grid>
    </>
  )
}

export default CurrentSalesDaysDashboard
