import { useState } from "react"
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
} from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import EditIcon from "@material-ui/icons/Edit"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn"
import { Link as RouterLink, useHistory } from "react-router-dom"
import { gql } from "urql"
import { useQuery, useMutation, useAction } from "../../../hooks"
import DeleteButton from "../../../components/admin/DeleteButton"
import FabCreate from "../../../components/admin/FabCreate"

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

function UpdateSellingPointButton({ sellingPoint: { id } }) {
  return (
    <IconButton
      id={`update-${id}`}
      size="small"
      component={RouterLink}
      to={`/admin/selling-points/${id}/update`}
    >
      <EditIcon />
    </IconButton>
  )
}

const DeleteSellingPointMutation = gql`
  mutation ($id: ID!) {
    deleteSellingPoint(sellingPoint: { id: $id }) {
      id
    }
  }
`

function DeleteSellingPointButton({ sellingPoint: { id } }) {
  const deleteSellingPoint = useMutation(DeleteSellingPointMutation)

  return (
    <DeleteButton
      id={`delete-${id}`}
      onClick={() => deleteSellingPoint({ id })}
    />
  )
}

const EndPeriodMutation = gql`
  mutation ($id: ID!) {
    endPeriod(sellingPointId: $id) {
      id
    }
  }
`

function EndPeriodButton({ sellingPointId, commission }) {
  const action = useAction({
    mutation: EndPeriodMutation,
    successMessage: "La commission a bien été validée.",
  })

  const [open, setOpen] = useState(false)
  const [pending, setPending] = useState(false)

  const endPeriod = async () => {
    setPending(true)

    try {
      await action({
        id: sellingPointId,
      })
      setOpen(false)
    } finally {
      setPending(false)
    }
  }

  return (
    <>
      <IconButton onClick={() => setOpen(true)} size="small">
        <AssignmentTurnedInIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="end-period-dialog-title"
        aria-describedby="end-period-dialog-description"
      >
        <DialogTitle id="end-period-dialog-title">
          Validation de la commission
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="end-period-dialog-description">
            En appuyant sur valider, vous attestez que la commission a été
            reversée au responsable du point de vente.
          </DialogContentText>

          <DialogContentText>
            La commission est de <strong>{commission / 100} €</strong>.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)}>Annuler</Button>
          <LoadingButton pending={pending} onClick={endPeriod}>
            Valider
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

function ActionsMenu({ sellingPoint }) {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const redirectTo = (to) => {
    history.push(to)
    handleClose()
  }

  return (
    <>
      <IconButton
        size="small"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() =>
            redirectTo(`/admin/selling-points/${sellingPoint.id}/sales-days`)
          }
        >
          Voir les journées de vente
        </MenuItem>
        <MenuItem
          onClick={() =>
            redirectTo(`/admin/selling-points/${sellingPoint.id}/items/update`)
          }
        >
          Éditer les invendus
        </MenuItem>
        <MenuItem
          onClick={() =>
            redirectTo(
              `/admin/selling-points/${sellingPoint.id}/deliveries-items/update`
            )
          }
        >
          Éditer la livraison
        </MenuItem>
      </Menu>
    </>
  )
}

const SellingPointsQuery = gql`
  query {
    sellingPoints {
      id
      name
      currentPeriod {
        commission
      }
    }
  }
`

function SellingPoints() {
  const { sellingPoints } = useQuery(SellingPointsQuery)

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Liste des points de ventes
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="Selling points">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sellingPoints.map((sellingPoint) => (
              <TableRow
                id={`get-${sellingPoint.id}`}
                key={sellingPoint.id}
                sx={hideLastBorder}
              >
                <TableCell>{sellingPoint.name}</TableCell>
                <TableCell sx={{ py: 0 }}>
                  <UpdateSellingPointButton sellingPoint={sellingPoint} />
                  <DeleteSellingPointButton sellingPoint={sellingPoint} />
                  {sellingPoint.currentPeriod.commission !== null && (
                    <EndPeriodButton
                      sellingPointId={sellingPoint.id}
                      commission={sellingPoint.currentPeriod.commission}
                    />
                  )}
                  <ActionsMenu sellingPoint={sellingPoint} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <FabCreate route="/admin/selling-points/create" />
    </>
  )
}

export default SellingPoints
