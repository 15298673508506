import { Snackbar, Alert } from "@material-ui/core"
import { useRecoilState } from "recoil"
import { currentSnackbarState } from "../atoms"

function CurrentSnackbar() {
  const [currentSnackbar, setCurrentSnackbar] =
    useRecoilState(currentSnackbarState)

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setCurrentSnackbar(null)
  }

  return (
    <Snackbar
      key={currentSnackbar ? currentSnackbar.key : undefined}
      open={!!currentSnackbar}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity={currentSnackbar?.severity}
        elevation={6}
        variant="filled"
      >
        {currentSnackbar?.message}
      </Alert>
    </Snackbar>
  )
}

export default CurrentSnackbar
