import { Autocomplete as MUIAutocomplete, TextField } from "@material-ui/core"
import { useField } from "formik"

function FormikAutocomplete({ helperText, label, ...props }) {
  const [field, { error, touched }, { setValue }] = useField(props)

  const handleChange = (event, value) => {
    setValue(value)
  }

  const hasError = touched && Boolean(error)

  return (
    <MUIAutocomplete
      {...field}
      onChange={handleChange}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          error={hasError}
          helperText={hasError ? error : helperText}
          label={label}
        />
      )}
    />
  )
}

export default FormikAutocomplete
