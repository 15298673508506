import WeightField from "./WeightField"
import { useField } from "formik"

function FormikWeightField(props) {
  const [, meta, helpers] = useField(props)

  const { value } = meta
  const { setValue } = helpers

  return <WeightField value={value} onChange={setValue} {...props} />
}

export default FormikWeightField
