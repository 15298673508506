import { Typography, Grid } from "@material-ui/core"

import { useParams } from "react-router-dom"
import { gql } from "urql"
import { Formik, Form } from "formik"
import { useQuery, useAction } from "../../../../../../hooks"
import { format, parse } from "date-fns"
import { fr } from "date-fns/locale/index.js"
import { LoadingButton } from "@material-ui/lab"
import {
  FormikMoneyField,
  FormikDatetimeField,
} from "../../../../../../components/fields"

const updateSalesDayMutation = gql`
  mutation ($input: UpdateSalesDayInput!) {
    updateSalesDay(input: $input) {
      id
    }
  }
`

const sellingPointSalesDaysQuery = gql`
  query ($salesDayId: ID!) {
    users {
      id
      firstName
      lastName
      email
    }

    salesDay(id: $salesDayId) {
      id
      salesDayState
      # Update
      ## Total
      totalCash
      totalCheck
      totalCard
      ## Seller
      sellerCashFund
      cashFundExpensesTotal

      #
      sellerCashFundSubmittedAt
      salesClosedAt
      unsoldStockCheckedAt
      feedbackSubmittedAt
      sellerEndOfFundSubmittedAt
      closedAt

      sellers {
        id
      }
      closedBy {
        id
      }
      turnover
      date
      closedAt
    }
  }
`

function UpdateSalesDay() {
  const { sellingPointId, salesDayId } = useParams()
  const { salesDay } = useQuery(sellingPointSalesDaysQuery, {
    salesDayId,
  })
  const updateSalesDay = useAction({
    mutation: updateSalesDayMutation,
    successMessage: "La journée de vente a bien été modifiée",
    redirectTo: `/admin/selling-points/${sellingPointId}/sales-days`,
  })

  async function handleSubmit(values) {
    await updateSalesDay({
      input: {
        id: salesDayId,
        // Money
        totalCash: values.totalCash * 100,
        totalCheck: values.totalCheck * 100,
        totalCard: values.totalCard * 100,
        sellerCashFund: values.sellerCashFund * 100,
        cashFundExpensesTotal: values.cashFundExpensesTotal * 100,
        sellerEndOfFund: values.sellerEndOfFund * 100,

        // Time
        sellerCashFundSubmittedAt: fromDatetimeMaskToTime(
          values.sellerCashFundSubmittedAt
        ),
        salesClosedAt: fromDatetimeMaskToTime(values.salesClosedAt),
        unsoldStockCheckedAt: fromDatetimeMaskToTime(
          values.unsoldStockCheckedAt
        ),
        feedbackSubmittedAt: fromDatetimeMaskToTime(values.feedbackSubmittedAt),
        sellerEndOfFundSubmittedAt: fromDatetimeMaskToTime(
          values.sellerEndOfFundSubmittedAt
        ),
        closedAt: fromDatetimeMaskToTime(values.closedAt),
      },
    })
  }

  const datetimeFormat = "dd/MM/yyyy-HH:mm"

  function fromTimeToDatetimeMask(time) {
    return format(new Date(time), datetimeFormat, {
      locale: fr,
    })
  }

  function fromDatetimeMaskToTime(datetimeMask) {
    return parse(datetimeMask, datetimeFormat, new Date())
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Journée de vente du {format(salesDay.date, "PPP", { locale: fr })}
      </Typography>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          // Turnover
          totalCash: (salesDay.totalCash ?? 0) / 100,
          totalCheck: (salesDay.totalCheck ?? 0) / 100,
          totalCard: (salesDay.totalCard ?? 0) / 100,
          // Cash fund
          sellerCashFund: (salesDay.sellerCashFund ?? 0) / 100,
          cashFundExpensesTotal: (salesDay.cashFundExpensesTotal ?? 0) / 100,
          sellerEndOfFund: (salesDay.sellerEndOfFund ?? 0) / 100,
          // Time
          sellerCashFundSubmittedAt: fromTimeToDatetimeMask(
            salesDay.sellerCashFundSubmittedAt ?? Date.now()
          ),
          salesClosedAt: fromTimeToDatetimeMask(
            salesDay.salesClosedAt ?? Date.now()
          ),
          unsoldStockCheckedAt: fromTimeToDatetimeMask(
            salesDay.unsoldStockCheckedAt ?? Date.now()
          ),
          feedbackSubmittedAt: fromTimeToDatetimeMask(
            salesDay.feedbackSubmittedAt ?? Date.now()
          ),
          sellerEndOfFundSubmittedAt: fromTimeToDatetimeMask(
            salesDay.sellerEndOfFundSubmittedAt ?? Date.now()
          ),
          closedAt: fromTimeToDatetimeMask(salesDay.closedAt ?? Date.now()),
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              {/* Turnover */}
              <Grid item xs={12} md={4}>
                <FormikMoneyField name="totalCash" label="Total cash" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikMoneyField name="totalCheck" label="Total chèque" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikMoneyField name="totalCard" label="Total card" />
              </Grid>

              {/* Cash fund */}
              <Grid item xs={12} md={4}>
                <FormikMoneyField
                  name="sellerCashFund"
                  label="Fond de caisse vendeur"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikMoneyField
                  name="cashFundExpensesTotal"
                  label="Dépense caisse"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikMoneyField
                  name="sellerEndOfFund"
                  label="Fin de caisse vendeur"
                />
              </Grid>

              {/* Time */}
              <Grid item xs={12} md={6} xl={4}>
                <FormikDatetimeField
                  name="sellerCashFundSubmittedAt"
                  label="Fond de caisse soumit à"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <FormikDatetimeField
                  name="salesClosedAt"
                  label="Caisse enregitreuse fermée à"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <FormikDatetimeField
                  name="unsoldStockCheckedAt"
                  label="Invendus contrôlés à"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <FormikDatetimeField
                  name="feedbackSubmittedAt"
                  label="Informations additionnelles soumises à"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <FormikDatetimeField
                  name="sellerEndOfFundSubmittedAt"
                  label="Fin de caisse vendeur soumis à"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <FormikDatetimeField
                  name="closedAt"
                  label="Journée clôturée à"
                />
              </Grid>
            </Grid>
            <LoadingButton
              variant="contained"
              sx={{ mt: 2 }}
              pending={isSubmitting}
              type="submit"
            >
              Enregistrer
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default UpdateSalesDay
