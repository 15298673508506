import { useQuery as useUrqlQuery, useMutation as useUrqlMutation } from "urql"
import { useHistory } from "react-router-dom"
import { useSetCurrentSnackbar } from "."

function useQuery(query, variables, context) {
  const [{ data }] = useUrqlQuery({
    query,
    variables,
    context,
  })

  return data
}

function useMutation(mutation) {
  const [, executeMutation] = useUrqlMutation(mutation)

  return async (variables) => {
    const result = await executeMutation(variables)

    if (result.error) {
      throw result.error
    }

    return result
  }
}

function useAction({ mutation, successMessage, redirectTo }) {
  const history = useHistory()
  const setCurrentSnackbar = useSetCurrentSnackbar()
  const executeMutation = useMutation(mutation)

  return async (variables) => {
    try {
      const response = await executeMutation(variables)

      if (redirectTo) {
        history.push(redirectTo)
      }

      setCurrentSnackbar({
        severity: "success",
        message: successMessage,
      })

      return response
    } catch (e) {
      setCurrentSnackbar({
        severity: "error",
        message: "Une erreur est survenue, veuillez réessayer.",
      })

      throw e
    }
  }
}

export { useQuery, useMutation, useAction }
