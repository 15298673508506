import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Select,
  MenuItem
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import { Link as RouterLink } from "react-router-dom"
import { Formik, Form } from "formik"
import { gql } from "urql"
import { useQuery, useMutation } from "../../../hooks"
import DeleteButton from "../../../components/admin/DeleteButton"
import FabCreate from "../../../components/admin/FabCreate"

const SetProductVisibilityMutation = gql`
  mutation ($product: ID!, $isHidden: Boolean!) {
    setProductVisibility(product: $product, isHidden: $isHidden) {
      id
    }
  }
`

function ProductHiddenButton({ product: { id, isHidden } }) {
  const setProductVisibility = useMutation(SetProductVisibilityMutation)

  async function handleClick() {
    await setProductVisibility({
      product: id,
      isHidden: !isHidden,
    })
  }

  return (
    <Formik initialValues={{}} onSubmit={handleClick}>
      {({ isSubmitting }) => (
        <Form>
          <IconButton
            id={`setisHidden-${id}`}
            size="small"
            onClick={handleClick}
            disabled={isSubmitting}
          >
            {isHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Form>
      )}
    </Formik>
  )
}

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

function UpdateProductButton({ product: { id } }) {
  return (
    <IconButton
      id={`update-${id}`}
      size="small"
      component={RouterLink}
      to={`/admin/products/${id}/update`}
    >
      <EditIcon />
    </IconButton>
  )
}

const DeleteProductMutation = gql`
  mutation ($id: ID!) {
    deleteProduct(product: { id: $id }) {
      id
    }
  }
`

function DeleteProductButton({ product: { id } }) {
  const deleteProduct = useMutation(DeleteProductMutation)

  return (
    <DeleteButton id={`delete-${id}`} onClick={() => deleteProduct({ id })} />
  )
}

const ProductsQuery = gql`
  {
    visibleProducts {
      id
      name
      variety
      packaging
      isHidden
      prices {
        first
        second
        third
      }
    }
    hiddenProducts {
      id
      name
      variety
      packaging
      isHidden
      prices {
        first
        second
        third
      }
    }
  }
`

function TableProducts({ products }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="products">
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell>Variété</TableCell>
            <TableCell>Packaging</TableCell>
            <TableCell>Prix</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {products.map((product) => (
            <TableRow
              id={`get-${product.id}`}
              key={product.id}
              sx={hideLastBorder}
            >
              <TableCell>{product.name}</TableCell>
              <TableCell>{product.variety}</TableCell>
              <TableCell>{product.packaging}</TableCell>
              <TableCell>
                <Select value="first" variant="outlined" size="small">
                  <MenuItem value="first">Premier : {product.prices.first / 100} €</MenuItem>
                  <MenuItem value="second">Second : {product.prices.second / 100} €</MenuItem>
                  <MenuItem value="third">Troisième : {product.prices.third / 100} €</MenuItem>
                </Select>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <UpdateProductButton product={product} />
                <DeleteProductButton product={product} />
                <ProductHiddenButton product={product} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function Products() {
  const { visibleProducts, hiddenProducts } = useQuery(ProductsQuery)

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Liste des produits
      </Typography>

      <Typography variant="h6" gutterBottom>
        Produits disponibles
      </Typography>
      <TableProducts products={visibleProducts} />

      <Typography variant="h6" sx={{ mt: 3 }} gutterBottom>
        Produits indisponibles
      </Typography>
      <TableProducts products={hiddenProducts} />

      <FabCreate route="/admin/products/create" />
    </>
  )
}

export default Products
