import { Typography } from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { useParams } from "react-router-dom"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { FormikCashFundField } from "../../../components/fields"
import { gql } from "urql"
import { useAction } from "../../../hooks"

const validationSchema = Yup.object({
  endOfFund: Yup.number()
    .required("Veuillez saisir la fin de caisse")
    .positive("Veuillez saisir un nombre positif"),
})

const SubmitEndOfFundMutation = gql`
  mutation ($endOfFund: SubmitEndOfFundInput!) {
    submitEndOfFund(endOfFund: $endOfFund) {
      id
    }
  }
`

function EndOfFundChecking() {
  const { salesDayId } = useParams()
  const submitEndOfFund = useAction({
    mutation: SubmitEndOfFundMutation,
    successMessage: "La fin de caisse a bien été enregistrée.",
  })

  const handleSubmit = async (data) => {
    const { endOfFund } = validationSchema.cast(data)

    await submitEndOfFund({
      endOfFund: {
        salesDayId,
        endOfFund: Math.round(endOfFund * 100),
      },
    })
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Fin de caisse
      </Typography>

      <Formik
        onSubmit={handleSubmit}
        initialValues={{ endOfFund: "" }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormikCashFundField name="endOfFund" label={"Fin de caisse"} />

            <LoadingButton
              pending={isSubmitting}
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Valider
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default EndOfFundChecking
