import { Typography } from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { useState } from "react"
import { gql, useClient } from "urql"

const exportForecastsQuery = gql`
  {
    csv {
      exportForecasts
    }
  }
`
const exportAffectedStockQuery = gql`
  {
    csv {
      exportAffectedStock
    }
  }
`

const exportBagAlertsQuery = gql`
  {
    csv {
      exportBagAlerts
    }
  }
`
const exportCashFundDetailsQuery = gql`
  {
    csv {
      exportCashFundDetails
    }
  }
`

const exportDailySellingPointProductSalesQuery = gql`
  {
    csv {
      exportDailySellingPointProductSales
    }
  }
`

const exportDailySellingPointTurnoversQuery = gql`
  {
    csv {
      exportDailySellingPointTurnovers
    }
  }
`
const exportSellingPointTurnoversQuery = gql`
  {
    csv {
      exportSellingPointTurnovers
    }
  }
`

function downloadCsv(csv, name) {
  if (!csv) {
    return
  }

  const element = document.createElement("a")
  const csvFile = new Blob([`\uFEFF${csv}`], {
    type: "text/csv;charset=utf-8,",
  })
  element.href = URL.createObjectURL(csvFile)
  element.download = name
  document.body.appendChild(element)
  element.click()
}

function ExportButton({ name, filename, label, query }) {
  const client = useClient()
  const [pending, setPending] = useState(false)

  const handleClick = async () => {
    try {
      setPending(true)

      const { data } = await client
        .query(query, null, { requestPolicy: "network-only" })
        .toPromise()

      downloadCsv(data.csv[name], `${filename}.csv`)
    } finally {
      setPending(false)
    }
  }

  return (
    <LoadingButton pending={pending} onClick={handleClick}>
      {label}
    </LoadingButton>
  )
}

function ExportCsv() {
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Exportation des données
      </Typography>
      <Typography variant="h4" gutterBottom>
        (Fichiers au format .csv)
      </Typography>
      <Typography>Prévision</Typography>
      <div>
        <ExportButton
          name={"exportForecasts"}
          filename={"prévisions"}
          label="Exporter les prévisions"
          query={exportForecastsQuery}
        />
      </div>
      <Typography>Distibution des stocks</Typography>
      <div>
        <ExportButton
          name={"exportAffectedStock"}
          filename={"preparation_entrepot"}
          label="Exporter la distribution des stocks"
          query={exportAffectedStockQuery}
        />
      </div>
      <Typography>Besoins</Typography>
      <div>
        <ExportButton
          name={"exportBagAlerts"}
          filename={"alertes_sacs"}
          label="Exporter les alertes de sacs"
          query={exportBagAlertsQuery}
        />
      </div>
      <div>
        <ExportButton
          name={"exportCashFundDetails"}
          filename={"détails_fond_de_caisse"}
          label="Exporter le détail du fond de caisse"
          query={exportCashFundDetailsQuery}
        />
      </div>
      {/* Products Sales */}
      <Typography>Ventes des produits</Typography>
      <div>
        <ExportButton
          name={"exportDailySellingPointProductSales"}
          filename={"ventes_produits_par_jour"}
          label="Exporter les ventes des produits par jour par point de vente"
          query={exportDailySellingPointProductSalesQuery}
        />
      </div>

      {/* Turnovers */}
      <Typography>Chiffre d'affaires</Typography>
      <div>
        <ExportButton
          name={"exportDailySellingPointTurnovers"}
          filename={"jours_de_vente"}
          label="Exporter les jours de vente"
          query={exportDailySellingPointTurnoversQuery}
        />
      </div>
      <div>
        <ExportButton
          name={"exportSellingPointTurnovers"}
          filename={"CA_globaux"}
          label="Exporter les CA globaux"
          query={exportSellingPointTurnoversQuery}
        />
      </div>
    </>
  )
}

export default ExportCsv
