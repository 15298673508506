import { useState } from "react"
import {
  Typography,
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core"
import ProductDialog from "./ProductDialog"
import ReduceTotalPriceDialog from "./ReduceTotalPriceDialog"
import EditIcon from "@material-ui/icons/Edit"
import { LoadingButton } from "@material-ui/lab"
import CloseIcon from "@material-ui/icons/Close"
import DeleteButton from "../../admin/DeleteButton"
import { CashFundField } from "../../fields"
import { produce } from "immer"
import { format } from "../../../utils"
import { useParams } from "react-router-dom"
import { gql } from "urql"
import { useQuery, useAction } from "../../../hooks"

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

const CreateSaleMutation = gql`
  mutation ($sale: CreateSaleInput!) {
    createSale(sale: $sale) {
      id
    }
  }
`
const SellerQuery = gql`
  query ($user: UserInput!) {
    user(user: $user) {
      id
      fullName
    }
  }
`

function SellerProductCartDialog({
  open,
  onClose,
  onCancel,
  products,
  setProducts,
  currentSellerId,
}) {
  const { sellingPointId, salesDayId } = useParams()
  const {
    user: { fullName },
  } = useQuery(SellerQuery, { user: { id: currentSellerId } })
  const createSale = useAction({
    mutation: CreateSaleMutation,
    successMessage: "La vente a bien été enregistrée.",
  })

  const [reduceTotalPriceDialog, setReduceTotalPriceDialog] = useState(false)
  const [percentageDiscount, setPercentageDiscount] = useState(0)
  const [eurosDiscount, setEurosDiscount] = useState(0)
  function handleReduceTotalPriceClick(values) {
    const { percentageDiscount, eurosDiscount } = values
    setPercentageDiscount(percentageDiscount)
    setEurosDiscount(eurosDiscount)
  }
  function reducedTotalPrice() {
    return Math.round(
      (getTotalProductsPrice() - eurosDiscount * 100) *
      (1 - percentageDiscount / 100)
    )
  }

  const [productSelectedIndex, setProductSelectedIndex] = useState(-1)

  if (open && products.length === 0) {
    onCancel()
    onClose()
  }

  const getTotalProductsPrice = () => {
    let total = 0
    for (const { totalPrice } of products) {
      total += totalPrice
    }
    return total
  }

  function getTotalProductsNumber() {
    let number = 0
    for (const { weight, quantity } of products) {
      number += weight ? 1 : quantity
    }
    return number
  }

  function isCartEmpty() {
    return products.length === 0
  }

  function handleDeleteProduct(index) {
    setProducts(
      produce(products, (draft) => {
        draft.splice(index, 1)
      })
    )
  }

  function handleUpdateProduct(sellerId, values) {
    setProducts(
      produce(products, (draft) => {
        if (!!values.quantity && !!values.weight) {
          draft.splice(productSelectedIndex, 1)
        }
        draft[productSelectedIndex] = values
      })
    )
  }

  function reset() {
    setCash("")
    setReduceTotalPriceDialog(false)
    setPercentageDiscount(0)
    setEurosDiscount(0)
    setProductSelectedIndex(-1)
  }

  function handleClose() {
    onClose()
    reset()
  }

  function handleCancel() {
    onCancel()
    onClose()
    reset()
  }

  const [cash, setCash] = useState("")
  function handleCashChange(event) {
    setCash(event.target?.value ?? event)
  }
  function getCashChange() {
    return ((cash * 1) - format.price(reducedTotalPrice())) * 100
  }

  const [loading, setLoading] = useState(false)
  const handleSubmit = async (paymentMethod) => {
    setLoading(true)

    // TODO: Refacto sale items
    const items = products.map(
      ({ product: { id, price }, totalPrice, quantity, weight }) => ({
        productId: id,
        price,
        totalPrice: Math.round(totalPrice),
        quantity: quantity,
        weight: weight ? Number(weight) : null,
      })
    )

    const sale = {
      salesDayId,
      sellingPointId,
      sellerId: currentSellerId,
      items,
      paymentMethod,
      totalPrice: reducedTotalPrice(),
    }

    try {
      await createSale({ sale })
      onCancel()
      onClose()
      reset()
    } catch (e) { }
    setLoading(false)
  }

  return (
    <>
      <ProductDialog
        open={productSelectedIndex !== -1}
        onClose={() => setProductSelectedIndex(-1)}
        onValidate={handleUpdateProduct}
        item={products[productSelectedIndex]?.product}
        values={products[productSelectedIndex]}
      />

      <ReduceTotalPriceDialog
        open={reduceTotalPriceDialog}
        onClose={() => setReduceTotalPriceDialog(false)}
        onValidate={handleReduceTotalPriceClick}
        values={{
          totalPrice: getTotalProductsPrice(),
          percentageDiscount,
          eurosDiscount,
        }}
      />

      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="cash-fund-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="cash-fund-dialog-title">
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">
                {fullName + " - " + format.currentDate()}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell>Prix</TableCell>
                  <TableCell>Quantité</TableCell>
                  <TableCell>Réduction</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {products.map((product, index) => (
                  <TableRow sx={hideLastBorder} key={index}>
                    <TableCell>{product.product.fullName}</TableCell>
                    <TableCell>
                      {format.price(product.product.price)} €
                    </TableCell>
                    <TableCell>
                      {`${product.quantity
                        ? product.quantity +
                        "  unité" +
                        (product.quantity > 1 ? "s" : "")
                        : product.weight + " kg"
                        } `}{" "}
                    </TableCell>
                    <TableCell>{`${product.eurosDiscount
                      ? " - " + product.eurosDiscount + " €"
                      : ""
                      } ${product.percentageDiscount
                        ? " - " + product.percentageDiscount + " %"
                        : ""
                      }`}</TableCell>
                    <TableCell>{format.price(product.totalPrice)} €</TableCell>

                    <TableCell sx={{ py: 0 }}>
                      <IconButton
                        size="small"
                        onClick={() => setProductSelectedIndex(index)}
                      >
                        <EditIcon />
                      </IconButton>
                      <DeleteButton
                        onClick={() => handleDeleteProduct(index)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container style={{ margin: 10 }} justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">
                Nombre de produits : {getTotalProductsNumber()}
              </Typography>
              <Grid container>
                <Grid item>
                  {eurosDiscount || percentageDiscount ? (
                    <>
                      <Typography variant="h5">
                        Total :{" "}
                        <span
                          style={{
                            marginRight: 10,
                            textDecoration: "line-through",
                          }}
                        >
                          {format.price(getTotalProductsPrice())}€
                        </span>
                        {format.price(reducedTotalPrice())}€
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="h5">
                      Total : {format.price(getTotalProductsPrice())} ‎€
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => setReduceTotalPriceDialog(true)}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                sx={{ m: 2 }}
                onClick={handleCancel}
                color="secondary"
                variant="contained"
              >
                Supprimer le ticket
              </Button>
            </Grid>
          </Grid>

          <Typography>Aide au calcul du rendu monnaie (facultatif):</Typography>

          <CashFundField
            name="cash"
            label="Montant espèces"
            value={cash}
            onChange={handleCashChange}
          />

          <Typography>
            Rendu monnaie : {format.price(getCashChange())} €
          </Typography>
          <Grid container justifyContent="space-around" alignItems="center" sx={{ my: 2 }}>
            <Grid item>
              <LoadingButton
                onClick={() => handleSubmit("CHECK")}
                color="check"
                disabled={isCartEmpty()}
                variant="contained"
                sx={{ ml: 6, width: 120 }}
                pending={loading}
              >
                Chèque
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                onClick={() => handleSubmit("CASH")}
                color="cash"
                disabled={isCartEmpty()}
                variant="contained"
                sx={{ ml: 6, width: 120, p: 4 }}
                pending={loading}
                size="large"
              >
                Espèces
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                onClick={() => handleSubmit("CARD")}
                color="tpe"
                disabled={isCartEmpty()}
                variant="contained"
                sx={{ ml: 6, width: 120, p: 4 }}
                pending={loading}
                size="large"
              >
                TPE
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SellerProductCartDialog
