import { TextField as MUITextField } from "@material-ui/core"
import { useField } from "formik"

function FormikTextField({ helperText, ...props }) {
  const [field, { error, touched }] = useField(props)

  const hasError = touched && Boolean(error)

  return (
    <MUITextField
      {...field}
      error={hasError}
      helperText={hasError ? error : helperText}
      {...props}
    />
  )
}

export default FormikTextField
