import { useEffect } from "react"
import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import {
  FormikNumericField,
  FormikWeightField,
  FormikMoneyField,
} from "../../fields"
import { format } from "../../../utils"
import { Formik, Form, useFormikContext } from "formik"

function FormListener({ onFormChange, fields }) {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    onFormChange(values, setFieldValue);
    // eslint-disable-next-line
  }, [...fields.map((field) => values[field])]);

  return null;
};

function ProductDialog({ open, onClose, onValidate, item, values, sellers }) {
  function reducedPrice({
    quantity,
    weight,
    eurosDiscount,
    percentageDiscount,
  }) {
    return (
      (item.price * (quantity ?? weight) - eurosDiscount * 100) *
      (1 - percentageDiscount / 100)
    )
  }

  function handleSubmit(sellerId, values) {
    onValidate(sellerId, {
      ...values,
      totalPrice: reducedPrice(values),
      product: item,
    })

    onClose()
  }

  function onFormChange(values, setFieldValue) {
    const offer = item.offer;

    if (offer === null) {
      return;
    }

    const normalPrice = item.price;
    const batchPrice = offer.offer;

    const quantityInABatch = offer.quantity;

    const quantity = values.quantity !== null ? values.quantity : values.weight;
    const batches = Math.floor(quantity / quantityInABatch);

    const priceDelta = Math.max(0, normalPrice * quantityInABatch - batchPrice);
    const eurosDiscount = batches * priceDelta;

    setFieldValue('eurosDiscount', eurosDiscount / 100)
  }

  if (!item) {
    return null
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="cash-fund-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <Formik
          initialValues={
            values ?? {
              quantity: item.packaging ? 1 : null,
              weight: item.packaging ? null : "",
              percentageDiscount: 0,
              eurosDiscount: 0,
            }
          }
          enableReinitialize
        >
          {({ values, isSubmitting }) => (
            <Form>
              <FormListener onFormChange={onFormChange} fields={['quantity', 'weight']} />

              <DialogTitle id="cash-fund-dialog-title">{`${item.fullName
                } - ${format.price(item.price)} €`}</DialogTitle>

              <DialogContent>
                {item.packaging ? (
                  <FormikNumericField name="quantity" label="Quantité" />
                ) : (
                  <FormikWeightField name="weight" label="Poids" />
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormikNumericField
                      name="percentageDiscount"
                      label="Réduction en pourcentage"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikMoneyField
                      name="eurosDiscount"
                      label="Réduction en euros"
                    />
                  </Grid>
                </Grid>

                <Typography variant="h6">
                  Total : {format.price(reducedPrice(values))} €
                </Typography>
              </DialogContent>
              <DialogActions>
                {!sellers ? <LoadingButton variant="contained" onClick={() => handleSubmit(null, values)}>Valider</LoadingButton> : sellers.map((seller) => <LoadingButton
                  variant="contained"
                  sx={{ backgroundColor: colorHash(seller.id) }}
                  pending={isSubmitting}
                  onClick={() => handleSubmit(seller.id, values)}
                  size="large"
                >{seller.fullName}</LoadingButton>)}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

function colorHash(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).substr(-2);
  }

  return color;
}

export default ProductDialog
