import { useState } from "react"
import { InputAdornment, IconButton } from "@material-ui/core"
import EuroIcon from "@material-ui/icons/Euro"
import FormikCashFundDetailsDialog from "./FormikCashFundDetailsDialog"
import MoneyField from "./MoneyField"
import { useField } from "formik"

function CashFundDetailsField({ cashFundDetailsName, values, ...props }) {
  const [, { error, touched }] = useField({ name: cashFundDetailsName })
  const hasError = touched && Boolean(error)

  const [dialogOpen, setDialogOpen] = useState(false)

  const totalCashFund = () => {
    let total = 0

    for (const [value, quantity] of Object.entries(values)) {
      total += value * quantity
    }

    return total / 100
  }

  return (
    <>
      <FormikCashFundDetailsDialog
        name={cashFundDetailsName}
        totalCashFund={totalCashFund()}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <MoneyField
        label="Total fond de caisse"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setDialogOpen(true)}>
                <EuroIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        disabled
        onClick={() => setDialogOpen(true)}
        value={totalCashFund()}
        error={hasError}
        helperText={hasError ? error : ""}
        {...props}
      />
    </>
  )
}

export default CashFundDetailsField
