function getDateParams(dateToFormat) {
  const dd = String(dateToFormat.getDate()).padStart(2, "0")
  const mm = String(dateToFormat.getMonth() + 1).padStart(2, "0")
  const yyyy = dateToFormat.getFullYear()

  return { dd, mm, yyyy }
}

function date(dateToFormat) {
  const { dd, mm, yyyy } = getDateParams(dateToFormat)
  return `${dd}/${mm}/${yyyy}`
}

function currentDate() {
  return date(new Date())
}

function firebaseDocumentDate(dateToFormat) {
  const { dd, mm, yyyy } = getDateParams(dateToFormat)
  return `${yyyy}-${mm}-${dd}`
}

function currentFirebaseDocumentDate() {
  return firebaseDocumentDate(new Date())
}

function price(priceToFormat) {
  return ((Math.ceil(priceToFormat / 5) * 5) / 100).toFixed(2)
}

export {
  date,
  currentDate,
  currentFirebaseDocumentDate,
  price,
  firebaseDocumentDate,
}
