import { Typography, Paper } from "@material-ui/core"
import { Doughnut } from "react-chartjs-2"

function PieChart({ values }) {
  return (
    <Doughnut
      data={{
        labels: values.total
          ? ["Total"]
          : ["Carte bancaire", "Espèces", "Chèque"],
        datasets: [
          {
            data: values.total
              ? [values.total]
              : [values.card, values.cash, values.check].map(
                  (value) => value / 100
                ),
            backgroundColor: ["#213ee5", "#7283e5", "#acb6f0"],
          },
        ],
      }}
      options={{
        legend: {
          display: true,
          position: "left",
        },
      }}
    />
  )
}

function PieChartCard({ title, values, sx, ...props }) {
  const total = values.total ?? values.card + values.cash + values.check

  return (
    <Paper sx={{ p: 3, ...sx }} {...props}>
      <Typography variant="h5" gutterBottom align="center">
        {title}
      </Typography>

      <PieChart values={values} />

      <Typography>Total : {total / 100} €</Typography>
      <Typography variant="h6">
        Commission : {values.commission / 100} €
      </Typography>
    </Paper>
  )
}

export default PieChartCard
