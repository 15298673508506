import { Paper, Typography, MenuItem } from "@material-ui/core"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import {
  FormikTextField,
  FormikMoneyField,
  FormikColorField,
} from "../../components/fields"
import { packagings } from "../../utils"
import ProductCard from "../../components/ProductCard"
import { LoadingButton } from "@material-ui/lab"

const priceSchema = Yup.number()
  .required("Veuillez saisir un prix")
  .positive("Le prix doit être positif")

const validationSchema = Yup.object({
  name: Yup.string().required("Veuillez saisir un nom"),
  variety: Yup.string(),
  packaging: Yup.string(),
  color: Yup.string().required(),
  prices: Yup.object({
    first: priceSchema,
    second: priceSchema,
    third: priceSchema,
  }).required(),
})

function encodePrice(price) {
  return Math.round(parseFloat(price) * 100)
}

function ProductForm({ product, onSubmit }) {
  const handleSubmit = async ({ prices, ...data }) => {
    await onSubmit({
      ...data,
      prices: {
        first: encodePrice(prices.first),
        second: encodePrice(prices.second),
        third: encodePrice(prices.third),
      },
    })
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={
        product ?? {
          name: "",
          variety: "",
          packaging: "",
          color: "#ffffff",
          prices: {
            first: "",
            second: "",
            third: "",
          },
        }
      }
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <FormikTextField name="name" label="Nom" />
          <FormikTextField name="variety" label="Variété" />

          <FormikTextField
            name="packaging"
            dataTestId="packaging"
            label="Packaging"
            helperText="Laisser vide si ce produit est vendu au poids"
            select
          >
            <MenuItem value="">
              <em>Vendu au poids</em>
            </MenuItem>

            {packagings.map((packaging) => (
              <MenuItem key={packaging} value={packaging}>
                {packaging}
              </MenuItem>
            ))}
          </FormikTextField>

          <Paper sx={{ p: 2 }}>
            <Typography variant="body1" sx={{ mb: 2, color: "text.secondary" }}>
              Couleur
            </Typography>

            <FormikColorField name="color" />
          </Paper>

          <FormikMoneyField name="prices.first" label="Prix classe 1" />
          <FormikMoneyField name="prices.second" label="Prix classe 2" />
          <FormikMoneyField name="prices.third" label="Prix classe 3" />

          <Typography variant="body1">
            Apperçu du produit sur la caisse de vente
          </Typography>

          <ProductCard
            product={{ ...values, id: 1, price: values.prices.first * 100 }}
            cartProducts={[]}
          />

          <LoadingButton
            pending={isSubmitting}
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
          >
            {product ? "Éditer" : "Créer"}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  )
}

export default ProductForm
