import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { useParams } from "react-router-dom"
import { gql } from "urql"
import { useQuery, useAction } from "../../../hooks"
import { FormikNumericField } from "../../../components/fields"
import { Formik, Form } from "formik"

const CheckWastedStockMutation = gql`
  mutation ($wastedStock: CheckWastedStockInput!) {
    checkWastedStock(wastedStock: $wastedStock) {
      id
    }
  }
`

const WastedStockCheckingQuery = gql`
  query ($sellingPointId: ID!) {
    sellingPoint(sellingPoint: { id: $sellingPointId }) {
      id

      items {
        product {
          id
          fullName
        }
      }
    }
  }
`

function WastedStockChecking() {
  const { sellingPointId } = useParams()
  const {
    sellingPoint: { items },
  } = useQuery(WastedStockCheckingQuery, {
    sellingPointId,
  })

  const checkWastedStock = useAction({
    mutation: CheckWastedStockMutation,
    successMessage: "Le stock a bien été mis à jour.",
  })

  const handleSubmit = async ({ items }) => {
    await checkWastedStock({
      wastedStock: {
        sellingPointId,
        items,
      },
    })
  }

  const initialValues = {
    items: items.map((item) => ({
      productId: item.product.id,
      quantity: 0,
    })),
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Contrôle des stocks - Invendu / Perte
      </Typography>

      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ isSubmitting }) => (
          <Form>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Produit</TableCell>
                    <TableCell>Pertes</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {items.map((item, index) => (
                    <TableRow>
                      <TableCell>{item.product.fullName}</TableCell>
                      <TableCell>
                        <FormikNumericField name={`items[${index}].quantity`} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <LoadingButton
              pending={isSubmitting}
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Valider
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default WastedStockChecking
