import MoneyField from "./MoneyField"
import { useField } from "formik"

function FormikMoneyField({ helperText, ...props }) {
  const [field, { error, touched }] = useField(props)

  const hasError = touched && Boolean(error)

  return (
    <MoneyField
      {...field}
      error={hasError}
      helperText={hasError ? error : helperText}
      {...props}
    />
  )
}

export default FormikMoneyField
