import { Suspense } from "react"
import { StylesProvider, CssBaseline } from "@material-ui/core"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import { frFR } from "@material-ui/core/locale"
import { RecoilRoot } from "recoil"
import { useFirebaseApp, preloadFunctions, AuthCheck } from "reactfire"
import { Router, Switch, Route, Redirect } from "react-router-dom"
import CurrentSnackbar from "./components/CurrentSnackbar"
import "./App.css"

// General
import AuthLayout from "./layouts/Auth"
import SignInView from "./views/auth/SignIn"
import SendPasswordResetEmailView from "./views/auth/SendPasswordResetEmail"
import PasswordResetEmailConfirmedView from "./views/auth/PasswordResetEmailConfirmed"
import RolesView from "./views/Roles"

// User
import ProfileView from "./views/user/Profile"
import NoRoleView from "./views/NoRole"

// Manager
import ManagerLayout from "./layouts/Manager"
import SellingPointsView from "./views/manager/SellingPoints"
import ManagerCurrentSalesDays from "./views/manager/salesDays/current"
import ManagerUnclosedSalesDays from "./views/manager/salesDays/unclosed/index"
import CloseSalesDayView from "./views/manager/salesDays/unclosed/_salesDayId.js"
import SellingPointView from "./views/manager/SellingPoint"
import EndOfDayView from "./views/manager/EndOfDay"
import StartNewDeliveryView from "./views/manager/NewDelivery/StartNewDelivery"
import NewDeliveryView from "./views/manager/NewDelivery"

// Seller
import SellerLayout from "./layouts/Seller"
import SellerSellingPoints from "./views/seller/SellingPoints"
import SellerSellingPoint from "./views/seller/SellingPoint"
import SellerSellingPointSalesDaySales from "./views/seller/SellingPoint/sales"

import AdminRoute from "./AdminRoute"

// Theme
import { blue, green, indigo, amber } from "@material-ui/core/colors"
const theme = createMuiTheme(
  {
    components: {
      MuiTableContainer: {
        defaultProps: {
          variant: "outlined",
        },
      },

      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          margin: "normal",
          variant: "outlined",
        },
      },

      MuiPaper: {
        defaultProps: {
          variant: "outlined",
        },
      },
    },
    palette: {
      // Product category
      firstCategory: { main: green[500] },
      secondCategory: { main: amber[700] },
      // Payment Method
      tpe: { main: indigo[500] },
      cash: { main: green[400] },
      check: { main: blue[300] },
    },
  },
  frFR
)

function HomeRoute() {
  return (
    <Route exact path="/">
      <AuthCheck fallback={<Redirect to="/auth/sign-in" />}>
        <Redirect to="/roles" />
      </AuthCheck>
    </Route>
  )
}

function PrivateRoute({ children, ...props }) {
  return (
    <Route
      {...props}
      render={({ location }) => (
        <AuthCheck
          fallback={
            <Redirect
              to={{ pathname: "/auth/sign-in", state: { from: location } }}
            />
          }
        >
          {children}
        </AuthCheck>
      )}
    />
  )
}

function App({ history }) {
  const firebaseApp = useFirebaseApp()

  preloadFunctions({
    firebaseApp,
    setup(factory) {
      const functions = factory()
      functions.region = "europe-west3"
    },
  })

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <CssBaseline />

          <Router history={history}>
            <Switch>
              <Suspense fallback={<div />}>
                {/* General */}
                <HomeRoute />

                <Route path="/auth">
                  <AuthLayout>
                    <Switch>
                      <Route path="/auth/sign-in" exact>
                        <SignInView />
                      </Route>
                      <Route path="/auth/send-password-reset" exact>
                        <SendPasswordResetEmailView />
                      </Route>
                      <Route path="/auth/password-reset-email-confirmed" exact>
                        <PasswordResetEmailConfirmedView />
                      </Route>
                    </Switch>
                  </AuthLayout>
                </Route>

                <PrivateRoute path="/roles">
                  <AuthLayout>
                    <Switch>
                      <Route path="/roles" exact>
                        <RolesView />
                      </Route>
                      <Route path="/roles/no-role" exact>
                        <NoRoleView />
                      </Route>
                    </Switch>
                  </AuthLayout>
                </PrivateRoute>

                <PrivateRoute path="/user">
                  <SellerLayout>
                    <Switch>
                      <Route path="/user/profile" exact>
                        <ProfileView />
                      </Route>
                    </Switch>
                  </SellerLayout>
                </PrivateRoute>

                {/* Manager */}
                <PrivateRoute path="/manager">
                  <ManagerLayout>
                    <Switch>
                      <Route path="/manager/selling-points" exact>
                        <SellingPointsView />
                      </Route>

                      <Route path="/manager/sales-days/current" exact>
                        <ManagerCurrentSalesDays />
                      </Route>

                      <Route path="/manager/sales-days/unclosed" exact>
                        <ManagerUnclosedSalesDays />
                      </Route>

                      <Route
                        path="/manager/sales-days/unclosed/:salesDayId"
                        exact
                      >
                        <CloseSalesDayView />
                      </Route>

                      <Route
                        path="/manager/selling-points/:sellingPointId"
                        exact
                      >
                        <SellingPointView />
                      </Route>

                      <Route
                        path="/manager/selling-points/:sellingPointId/end-of-day"
                        exact
                      >
                        <EndOfDayView />
                      </Route>

                      <Route
                        path="/manager/selling-points/:sellingPointId/start-new-delivery"
                        exact
                      >
                        <StartNewDeliveryView />
                      </Route>
                      <Route
                        path="/manager/selling-points/:sellingPointId/new-delivery"
                        exact
                      >
                        <NewDeliveryView />
                      </Route>
                    </Switch>
                  </ManagerLayout>
                </PrivateRoute>

                {/* Seller */}
                <PrivateRoute path="/seller">
                  <Switch>
                    <Route path="/seller/selling-points" exact>
                      <SellerLayout>
                        <SellerSellingPoints />
                      </SellerLayout>
                    </Route>
                  </Switch>
                  <Switch>
                    <Route
                      path="/seller/selling-points/:sellingPointId/:salesDayId/sales"
                      exact
                    >
                      <SellerLayout>
                        <SellerSellingPointSalesDaySales />
                      </SellerLayout>
                    </Route>
                  </Switch>
                  <Switch>
                    <Route
                      path="/seller/selling-points/:sellingPointId/:salesDayId"
                      exact
                    >
                      <SellerLayout>
                        <SellerSellingPoint />
                      </SellerLayout>
                    </Route>
                  </Switch>
                </PrivateRoute>

                <AdminRoute />
              </Suspense>
            </Switch>
          </Router>

          <CurrentSnackbar />
        </RecoilRoot>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default App
