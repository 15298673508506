import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import { Link as RouterLink } from "react-router-dom"
import { gql } from "urql"
import { useQuery, useMutation } from "../../../hooks"
import DeleteButton from "../../../components/admin/DeleteButton"
import FabCreate from "../../../components/admin/FabCreate"

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

function UpdateTruckButton({ truckId }) {
  return (
    <IconButton
      size="small"
      component={RouterLink}
      to={`/admin/trucks/${truckId}/update`}
    >
      <EditIcon />
    </IconButton>
  )
}

const DeleteTruckMutation = gql`
  mutation ($id: ID!) {
    deleteTruck(truck: { id: $id }) {
      id
    }
  }
`

function DeleteTruckButton({ truckId: id }) {
  const deleteTruck = useMutation(DeleteTruckMutation)

  return <DeleteButton onClick={() => deleteTruck({ id })} />
}

const TrucksQuery = gql`
  {
    trucks {
      id
      name
    }
  }
`

function Trucks() {
  const { trucks } = useQuery(TrucksQuery)

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Liste des camions
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="Camions">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>

              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {trucks.map((truck) => (
              <TableRow key={truck.id} sx={hideLastBorder}>
                <TableCell>{truck.name}</TableCell>

                <TableCell sx={{ py: 0 }}>
                  <UpdateTruckButton truckId={truck.id} />
                  <DeleteTruckButton truckId={truck.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <FabCreate route="/admin/trucks/create" />
    </>
  )
}

export default Trucks
