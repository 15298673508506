import { Typography } from "@material-ui/core"
import { gql } from "urql"
import { useAction } from "../../../hooks"
import UserForm from "../../../components/admin/UserForm"

const CreateUserMutation = gql`
  mutation ($user: CreateUserInput!) {
    createUser(user: $user) {
      id
    }
  }
`

function CreateUser() {
  const createUser = useAction({
    mutation: CreateUserMutation,
    successMessage: "L’utilisateur a bien été créé.",
    redirectTo: "/admin/users",
  })

  const handleSubmit = async (user) => {
    await createUser({ user })
  }

  return (
    <>
      <Typography variant="h2">Créer un utilisateur</Typography>

      <UserForm onSubmit={handleSubmit} />
    </>
  )
}

export default CreateUser
