import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Drawer as MaterialUIDrawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { Link as RouterLink, useRouteMatch } from "react-router-dom"

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,

  drawerPaper: {
    width: drawerWidth,
  },

  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

function ListItemLink({ icon, primary, to, ...props }) {
  const match = useRouteMatch(to)

  return (
    <ListItem
      {...props}
      button
      component={RouterLink}
      selected={match?.isExact}
      to={to}
    >
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}

      <ListItemText primary={primary} />
    </ListItem>
  )
}

function ListItemNested({ name, icon, links }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {links.map(({ name, to, ...props }, index) => (
            <ListItemLink
              key={to}
              primary={name}
              to={to}
              className={classes.nested}
              {...props}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}

function DrawerContent({ links }) {
  return (
    <List component="nav">
      {links.map(({ name, to, icon, links }, index) => {
        if (links) {
          return (
            <ListItemNested key={name} name={name} icon={icon} links={links} />
          )
        }

        return <ListItemLink key={to} primary={name} to={to} icon={icon} />
      })}
    </List>
  )
}

function Drawer({ open, onClose, links }) {
  const classes = useStyles()

  const drawerContent = <DrawerContent links={links} />

  const handleClose = onClose

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="js">
        <MaterialUIDrawer
          variant="temporary"
          classes={{ paper: classes.drawerPaper }}
          open={open}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawerContent}
        </MaterialUIDrawer>
      </Hidden>

      <Hidden smDown implementation="js">
        <MaterialUIDrawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <div className={classes.toolbar}></div>

          {drawerContent}
        </MaterialUIDrawer>
      </Hidden>
    </nav>
  )
}

export default Drawer
