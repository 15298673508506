import { useMemo, useState } from "react"
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fab,
  Menu,
  MenuItem,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Typography,
} from "@material-ui/core"
import { produce } from "immer"
import ProductDashboard from "../../../components/seller/cashRegister/ProductDashboard"
import SellerSelect from "../../../components/seller/cashRegister/SellerSelect"
import SellerProductCartDialog from "../../../components/seller/cashRegister/SellerProductCartDialog"
import { useHistory, useParams } from "react-router-dom"
import { gql } from "urql"
import { useQuery, useAction } from "../../../hooks"
import { useUser } from "reactfire"

// Fab
import { makeStyles } from "@material-ui/core/styles"
import AddIcon from "@material-ui/icons/Add"

// Statistics
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { FormikTextField } from "../../../components/fields"

import { priceToString } from "../../../utils/price"

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed !important",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

function FabMenu({ items }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const fab = {
    color: "secondary",
    className: classes.fab,
    icon: <AddIcon />,
    label: "Edit",
  }

  return (
    <>
      <Fab
        id="create"
        aria-label={fab.label}
        className={fab.className}
        color={fab.color}
        onClick={handleClick}
      >
        {fab.icon}
      </Fab>
      <Menu
        id="demo-positioned-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {items.map(({ onClick, label }) => (
          <MenuItem
            key={label}
            onClick={() => {
              onClick()
              handleClose()
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const SalesDayQuery = gql`
  query ($salesDayId: ID!) {
    salesDay(id: $salesDayId) {
      id
      turnover
      tickets
      sellersDetails {
        seller {
          id
          fullName
        }
        tickets
        turnover
        cartMean
      }
      sellingPoint {
        id
        name
      }
      totalProducts {
        product {
          name
          fullName
        }
        price
        sold
      }
    }
  }
`
const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

function SalesDayStatistics({ open, onClose }) {
  const { salesDayId } = useParams()
  // TODO: Make it works!!
  const context = useMemo(() => ({ additionalTypenames: ["Sales"] }), [])
  const { salesDay } = useQuery(SalesDayQuery, { salesDayId }, context)
  const [pinHasBeenEntered, setPinHasBeenEntered] = useState(false)
  const schema = Yup.object({
    pin: Yup.string()
      .required("Un code est requis")
      .equals(["2626"], "Le code n'est pas bon"),
  })

  function handleClose() {
    setPinHasBeenEntered(false)
    onClose()
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      {pinHasBeenEntered ? (
        <>
          <DialogTitle>
            Statistiques de la journée de vente actuelle
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Chiffre d'affaire</Typography>

                <Typography>{priceToString(salesDay.turnover)}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Nombre de tickets</Typography>

                <Typography>{salesDay.tickets}</Typography>
              </Grid>
            </Grid>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Ventes des produits
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Produit</TableCell>
                    <TableCell>Vendus</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {salesDay.totalProducts.map(
                    ({ product: { id, fullName }, price, sold }) => (
                      <TableRow key={id} sx={hideLastBorder}>
                        <TableCell component="th" scope="row">
                          {`${fullName} - ( ${priceToString(price)})`}
                        </TableCell>
                        <TableCell>{`${sold}`}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="h6" sx={{ mt: 1 }}>
              Détails des vendeurs
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Vendeur</TableCell>
                    <TableCell>CA</TableCell>
                    <TableCell>Tickets</TableCell>
                    <TableCell>Panier moyen</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {salesDay.sellersDetails.map(
                    ({
                      tickets,
                      turnover,
                      cartMean,
                      seller: { id, fullName },
                    }) => (
                      <TableRow key={id} sx={hideLastBorder}>
                        <TableCell>{fullName}</TableCell>
                        <TableCell>{priceToString(turnover)}</TableCell>
                        <TableCell>{tickets}</TableCell>
                        <TableCell>{priceToString(cartMean)}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Retourner à la caisse</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <Formik
            initialValues={{ pin: "" }}
            validationSchema={schema}
            onSubmit={() => setPinHasBeenEntered(true)}
          >
            <Form>
              <DialogTitle>
                Veuillez entrer le code pin pour voir les statistiques de la
                journée de vente actuelle
              </DialogTitle>
              <DialogContent>
                <FormikTextField name="pin" label="Code" />
              </DialogContent>
              <DialogActions>
                <Button type="submit">Soumettre</Button>
              </DialogActions>
            </Form>
          </Formik>
        </>
      )}
    </Dialog>
  )
}

const CloseSalesMutation = gql`
  mutation ($salesDayId: ID!) {
    closeSales(salesDayId: $salesDayId) {
      id
    }
  }
`

const CashRegisterQuery = gql`
  query ($sellingPointId: ID!) {
    sellingPoint(sellingPoint: { id: $sellingPointId }) {
      sellers {
        id
        fullName
      }
    }
  }
`

function CashRegister() {
  const history = useHistory()
  const { sellingPointId, salesDayId } = useParams()

  const {
    sellingPoint: { sellers: allSellers },
  } = useQuery(CashRegisterQuery, {
    sellingPointId,
  })

  const { uid: currentUserId } = useUser().data
  const closeSalesMutation = useAction({
    mutation: CloseSalesMutation,
    successMessage: "La vente est bien terminée.",
  })

  // SellerProductCartDialog
  const [sellerProductCartDialog, setSellerProductCartDialog] = useState(false)

  // sellers
  const [currentSellerId, setCurrentSellerId] = useState(currentUserId)
  const [sellers, setSellers] = useState({ [currentUserId]: { products: [] } })

  const sellersNames = Object.keys(sellers).map((sellerId) => allSellers.find((seller) => seller.id === sellerId))

  function addSellerToSellers(sellerId) {
    setSellers(
      produce(sellers, (draft) => {
        draft[sellerId] = {
          products: [],
        }
      })
    )
  }

  function removeSeller(sellerId) {
    setSellers(
      produce(sellers, (draft) => {
        delete draft[sellerId]
      })
    )
    setCurrentSellerId(currentUserId)
  }

  function updateSellerProducts(sellerId, products) {
    if (!sellers[sellerId]) {
      return
    }
    setSellers(
      produce(sellers, (draft) => {
        draft[sellerId].products = products
      })
    )
  }

  function resetSellerProducts(sellerId) {
    if (!sellers[sellerId]) {
      return
    }
    setSellers(
      produce(sellers, (draft) => {
        draft[sellerId].products = []
      })
    )
  }

  function addProductToSeller(sellerId, product) {
    setSellers(
      produce(sellers, (draft) => {
        draft[sellerId].products.push(product)
      })
    )
  }

  function handleCurrentSellerIdClick(sellerId) {
    if (sellers[sellerId].products.length > 0) {
      setCurrentSellerId(sellerId)
      setSellerProductCartDialog(true)
    }
  }

  const closeSales = async () => {
    await closeSalesMutation({ salesDayId })
    setConfirmSellingEndDialog(false)
  }

  // Confirm end of day
  const [confirmSellingEndDialog, setConfirmSellingEndDialog] = useState(false)
  function closeConfirmSellingEndDialog() {
    setConfirmSellingEndDialog(false)
  }
  function openConfirmSellingEndDialog() {
    setConfirmSellingEndDialog(true)
  }

  // Statistics
  const [statisticsDialog, setStatisticsDialog] = useState(false)

  return (
    <div>
      <SellerProductCartDialog
        open={sellerProductCartDialog}
        onClose={() => setSellerProductCartDialog(false)}
        onCancel={() => resetSellerProducts(currentSellerId)}
        products={sellers?.[currentSellerId]?.products ?? []}
        setProducts={(products) =>
          updateSellerProducts(currentSellerId, products)
        }
        currentSellerId={currentSellerId}
      />

      <div style={{ marginBottom: 5 }} />
      <SellerSelect
        onSellerCardClick={handleCurrentSellerIdClick}
        addSellerToSellers={addSellerToSellers}
        selectedSellers={sellers}
        removeSeller={removeSeller}
        sellers={allSellers}
      />
      <div style={{ marginBottom: 10 }} />

      <ProductDashboard
        addProductToSeller={addProductToSeller}
        sellers={sellersNames}
      />
      <FabMenu
        items={[
          {
            label: "Terminer la journée de vente",
            onClick: openConfirmSellingEndDialog,
          },
          {
            label: "Voir les statistiques de la journée de vente",
            onClick: () => setStatisticsDialog(true),
          },
          {
            label: "Modifier un ticket",
            onClick: () =>
              history.push(
                `/seller/selling-points/${sellingPointId}/${salesDayId}/sales`
              ),
          },
        ]}
      />

      <SalesDayStatistics
        open={statisticsDialog}
        onClose={() => setStatisticsDialog(false)}
      />

      <Dialog
        open={confirmSellingEndDialog}
        onClose={closeConfirmSellingEndDialog}
      >
        <DialogTitle>Confirmer la fin de la vente</DialogTitle>
        <DialogActions>
          <Button onClick={closeConfirmSellingEndDialog}>Annuler</Button>
          <Button onClick={closeSales} autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CashRegister
