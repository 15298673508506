import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import firebase from "firebase/app"
import "firebase/auth"
import { FirebaseAppProvider } from "reactfire"
import firebaseConfig from "./firebaseConfig"
import { createBrowserHistory } from "history"
import {
  createClient,
  Provider,
  dedupExchange,
  cacheExchange,
  fetchExchange,
} from "urql"
import fetchOptionsExchange from "./fetchOptionsExchange"

const production = process.env.NODE_ENV === "production"

let graphQLUrl = "http://localhost:4000"
let environment = "local"
if (process.env.REACT_APP_GAE_SERVICE === "default") {
  graphQLUrl = "https://graphql.easy-caisse.fr"
  environment = "prod"
} else if (production) {
  graphQLUrl = "https://dev.graphql.easy-caisse.fr"
  environment = "dev"
}

Sentry.init({
  dsn: "https://d12aba1bff914b6c84a1466493c242ea@o564973.ingest.sentry.io/5897704",
  environment,
})

firebase.initializeApp(firebaseConfig)

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    Sentry.setUser({ email: user.email, id: user.uid })
  } else {
    Sentry.configureScope((scope) => scope.setUser(null))
  }
})

const client = createClient({
  url: graphQLUrl,
  suspense: true,
  requestPolicy: "network-only",
  // requestPolicy: "cache-and-network",
  exchanges: [
    dedupExchange,
    cacheExchange,
    fetchOptionsExchange(async (fetchOptions) => {
      try {
        const idToken = await firebase.auth().currentUser.getIdToken()

        return {
          ...fetchOptions,
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      } catch (e) {
        console.error(e)

        return fetchOptions
      }
    }),
    fetchExchange,
  ],
})

const history = createBrowserHistory()

ReactDOM.unstable_createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
        <Provider value={client}>
          <App history={history} />
        </Provider>
      </FirebaseAppProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)

reportWebVitals()
