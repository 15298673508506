import { Typography } from "@material-ui/core"

import InnerLayout from "../../../components/seller/InnerLayout"

function EndOfDay() {
  return (
    <InnerLayout>
      <Typography variant="h2">La vente actuelle est terminée</Typography>
    </InnerLayout>
  )
}

export default EndOfDay
