import {
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
} from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { Formik, Form } from "formik"
import { useParams } from "react-router-dom"
import { gql } from "urql"
import { useQuery, useAction } from "../../../hooks"
import { FormikAutocomplete } from "../../../components/fields"
import WeekDaysSelect from "../../../components/admin/WeekDaysSelect"

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

const UpdateDeliveryDayMutation = gql`
  mutation ($deliveryDay: UpdateDeliveryDayInput!) {
    updateDeliveryDay(deliveryDay: $deliveryDay) {
      id

      truck {
        id
      }
    }
  }
`

const DeliveryDayQuery = gql`
  query ($weekDay: WeekDay!) {
    trucks {
      id
      name

      deliveries(weekDay: $weekDay) {
        sellingPoint {
          id
          type
        }
      }
    }

    sellingPoints {
      id
      name
      type
    }
  }
`

function DeliveryDay() {
  let { weekDay } = useParams()
  weekDay = weekDay.toUpperCase()
  const { trucks, sellingPoints } = useQuery(DeliveryDayQuery, { weekDay })
  const updateDeliveryDay = useAction({
    mutation: UpdateDeliveryDayMutation,
    successMessage: "Les livraisons ont bien été mises à jour.",
  })

  const handleSubmit = async (deliveryDay) => {
    await updateDeliveryDay({ deliveryDay })
  }

  const initialValues = {
    weekDay,
    trucks: trucks.map(({ id, deliveries }) => ({
      id,
      sellingPoints: deliveries.map(({ sellingPoint }) => sellingPoint.id),
    })),
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Livraisons
      </Typography>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <WeekDaysSelect mustConfirm={dirty} sx={{ mb: 2 }} />

            <TableContainer component={Paper}>
              <Table aria-label="deliveries">
                <TableHead>
                  <TableRow>
                    <TableCell>Camion</TableCell>
                    <TableCell>Points de ventes</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {trucks.map((truck, index) => (
                    <TableRow key={index} sx={hideLastBorder}>
                      <TableCell>{truck.name}</TableCell>

                      <TableCell sx={{ py: 0 }}>
                        <FormikAutocomplete
                          name={`trucks[${index}].sellingPoints`}
                          label="Points de ventes"
                          multiple
                          options={sellingPoints.map(({ id }) => id)}
                          getOptionLabel={(sellingPointId) => {
                            const sellingPoint = sellingPoints.find(
                              ({ id }) => id === sellingPointId
                            )

                            if (!sellingPoint) {
                              return ""
                            }

                            let sellingPointType = "stand"
                            if (sellingPoint.type === "STORE") {
                              sellingPointType = "magasin"
                            }

                            return `${sellingPoint.name} (${sellingPointType})`
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              pending={isSubmitting}
            >
              Enregistrer
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default DeliveryDay
