import { Typography, Grid, Card, CardContent } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { gql } from "urql"
import { useQuery } from "../../../../hooks"
import { format } from "date-fns"
import { fr } from "date-fns/locale/index.js"

const UnclosedManagerSalesDaysQuery = gql`
  {
    unclosedManagerSalesDaysQuery {
      id
      date
      salesDayStateLabel
      sellingPoint {
        id
        name
      }
    }
  }
`

function UnclosedSalesDays() {
  const history = useHistory()
  const { unclosedManagerSalesDaysQuery } = useQuery(
    UnclosedManagerSalesDaysQuery
  )

  return (
    <>
      <Typography variant="h2">Cloturer des journées de vente</Typography>

      <Grid container spacing={3}>
        {unclosedManagerSalesDaysQuery.map(
          ({ id, sellingPoint: { name }, date }) => (
            <Grid item xs={6} md={4} key={id}>
              <Card
                onClick={() =>
                  history.push(`/manager/sales-days/unclosed/${id}`)
                }
                style={{
                  cursor: "pointer",
                }}
              >
                <CardContent>
                  <Typography variant="h6">{name}</Typography>
                  <Typography>
                    {format(new Date(date), "PPP", { locale: fr })}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )
        )}
      </Grid>
    </>
  )
}

export default UnclosedSalesDays
