import { Typography } from "@material-ui/core"
import { gql } from "urql"
import { useAction } from "../../../hooks"
import SellingPointForm from "../../../components/admin/SellingPointForm"

const CreateSellingPointMutation = gql`
  mutation ($sellingPoint: CreateSellingPointInput!) {
    createSellingPoint(sellingPoint: $sellingPoint) {
      id
    }
  }
`

function CreateSellingPoint() {
  const createSellingPoint = useAction({
    mutation: CreateSellingPointMutation,
    successMessage: "Le point de vente a bien été créé.",
    redirectTo: "/admin/selling-points",
  })

  const handleSubmit = async (sellingPoint) => {
    await createSellingPoint({ sellingPoint })
  }

  return (
    <>
      <Typography variant="h2">Créer un point de vente</Typography>

      <SellingPointForm onSubmit={handleSubmit} />
    </>
  )
}

export default CreateSellingPoint
