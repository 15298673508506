const firebaseConfigProd = {
  apiKey: "AIzaSyA9OgmEdb9bOX-qF9COUHF5u7s3Ki5EBNY",
  authDomain: "easy-caisse.firebaseapp.com",
  projectId: "easy-caisse",
  storageBucket: "easy-caisse.appspot.com",
  messagingSenderId: "1059984504277",
  appId: "1:1059984504277:web:16dcdb55b16100a90c395c",
  measurementId: "G-ZE23125P2X",
}

/* const firebaseConfigDev = {
  apiKey: "AIzaSyCSJwVrRlHdXiBzAc9j5VTieEEUXoMevjo",
  authDomain: "easy-caisse-dev.firebaseapp.com",
  projectId: "easy-caisse-dev",
  storageBucket: "easy-caisse-dev.appspot.com",
  messagingSenderId: "27891100123",
  appId: "1:27891100123:web:1a5b0aa15709b1dd405ffe",
} */

let firebaseConfig = firebaseConfigProd
if (process.env.REACT_APP_GAE_SERVICE === "default") {
  firebaseConfig = firebaseConfigProd
}

export default firebaseConfig
