import { useMemo } from "react"
import { gql } from "urql"
import { useParams, useHistory } from "react-router-dom"
import { useQuery } from "../../../hooks"

// Stand
import NoProducts from "./NoProducts"
import UnsoldStockChecking from "./UnsoldStockChecking"
import CashFundChecking from "./CashFundChecking"
import EndOfFundChecking from "./EndOfFundChecking"
import CashRegister from "./CashRegister"
import Feedback from "./Feedback"
import EndOfDay from "./EndOfDay"

const SellingPointQuery = gql`
  query ($salesDayId: ID!) {
    me {
      managerOf {
        id
      }
    }

    salesDay(id: $salesDayId) {
      id
      salesDayState
    }
  }
`

function SellingPoint() {
  const context = useMemo(() => ({ additionalTypenames: ["SalesDay"] }), [])
  const history = useHistory()
  const { sellingPointId, salesDayId } = useParams()

  const {
    me: { managerOf },
    salesDay,
  } = useQuery(
    SellingPointQuery,
    {
      sellingPointId,
      salesDayId,
    },
    context
  )

  function redirectToManagerView() {
    const found = managerOf.find(({ id }) => id === sellingPointId)

    if (found) {
      history.push(`/manager/sales-days/unclosed/${salesDayId}`)
    }
  }

  if (!salesDay) {
    return <CashFundChecking />
  }

  switch (salesDay.salesDayState) {
    case "NoProducts":
      return <NoProducts />
    case "CashFundChecking":
      return <CashFundChecking />
    case "UnsoldStockChecking":
      return <UnsoldStockChecking />
    case "CashRegister":
      return <CashRegister />
    case "Feedback":
      return <Feedback />
    case "EndOfFundChecking":
      return <EndOfFundChecking />
    case "ManagerTotalChecking":
      redirectToManagerView()
      return <EndOfDay />
    default:
      return <EndOfDay />
  }
}

export default SellingPoint
