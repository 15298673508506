import { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"
import { gql } from "urql"
import { useParams } from "react-router-dom"
import { useQuery } from "../../../hooks"
import ProductDialog from "./ProductDialog"
import ProductCard from "../../../components/ProductCard"

const useStyles = makeStyles({
  container: {
    // height: window.innerHeight * 0.8 - 50,
    overflow: "auto",
  },
  productCard: {
    cursor: "pointer",
  },
})

const ProductDashboardQuery = gql`
  query ($salesDayId: ID!) {
    salesDay(id: $salesDayId) {
      items: initialProducts {
        id
        name
        price
        offer {
          quantity
          offer
        }
        variety
        packaging
        fullName
        color
      }
    }
  }
`

function ProductDashboard({ addProductToSeller, sellers }) {
  const classes = useStyles()
  const { salesDayId } = useParams()
  const {
    salesDay: { items },
  } = useQuery(ProductDashboardQuery, {
    salesDayId,
  })

  const [currentItem, setCurrentItem] = useState(null)
  const [productDialog, setProductDialog] = useState(false)

  function handleProductClick(productId) {
    setCurrentItem(items.find(({ id }) => id === productId))
    setProductDialog(true)
  }

  function addProduct(sellerId, values) {
    if (values.totalPrice < 0) {
      return
    }
    addProductToSeller(sellerId, values)

    setCurrentItem(null)
  }

  return (
    <>
      <ProductDialog
        open={productDialog}
        onClose={() => setProductDialog(false)}
        onValidate={addProduct}
        item={currentItem}
        sellers={sellers}
      />

      <Grid
        container
        alignItems="center"
        spacing={1}
        className={classes.container}
      >
        {items.map((item) => (
          <Grid
            key={item.id}
            item
            xs={12}
            sm={items.length < 12 ? 4 : 4}
            md={3}
          >
            <ProductCard
              product={item}
              onClick={handleProductClick}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default ProductDashboard
