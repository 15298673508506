import { Typography } from "@material-ui/core"

import InnerLayout from "../../../components/seller/InnerLayout"

function NoProducts() {
  return (
    <InnerLayout>
      <Typography variant="h2" gutterBottom>
        Aucun produit ne vous a été livré.
      </Typography>

      <Typography variant="h5">
        Vous ne pouvez donc pas commencer à vendre en utilisant cette
        application.
      </Typography>
    </InnerLayout>
  )
}

export default NoProducts
