const packagings = [
  "bq. 125 gr",
  "bq. 250 gr",
  "bq. 500 gr",
  "bq. 750 gr",
  "bq. 1 kg",
  "botte 1 kg",
  "botte 500 gr",
  "bq. 3 unités",
  "colis 3 kg",
  "colis 5 kg",
  "colis 6 kg",
  "unité",
]

export default packagings
