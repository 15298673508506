const containers = [
  { name: "Plateau de 4kg", quantity: 1, format: "", capacity: 4, unit: "kg" },
  {
    name: "Plateau de 4,5kg",
    quantity: 1,
    format: "",
    capacity: 4.5,
    unit: "kg",
  },
  { name: "Plateau de 5kg", quantity: 1, format: "", capacity: 5, unit: "kg" },
  { name: "Plateau de 6kg", quantity: 1, format: "", capacity: 6, unit: "kg" },
  { name: "Plateau de 8kg", quantity: 1, format: "", capacity: 8, unit: "kg" },
  {
    name: "Plateau de 6 barquettes de 1,1kg",
    quantity: 6,
    format: "bq. env. ",
    capacity: 1.1,
    unit: "kg",
  },
  {
    name: "Plateau de 6 barquettes de 1,2kg",
    quantity: 6,
    format: "bq. env. ",
    capacity: 1.2,
    unit: "kg",
  },
  {
    name: "Plateau de 6 barquettes de 1,3kg",
    quantity: 6,
    format: "bq. env. ",
    capacity: 1.3,
    unit: "kg",
  },
]

export default containers
