import { Typography } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { gql } from "urql"
import { produce } from "immer"
import { useQuery, useAction } from "../../../../hooks"
import TruckForm from "../../../../components/admin/TruckForm"

const TruckQuery = gql`
  query ($id: ID!) {
    truck(truck: { id: $id }) {
      name
    }
  }
`

const UpdateTruckMutation = gql`
  mutation ($truck: UpdateTruckInput!) {
    updateTruck(truck: $truck) {
      id
    }
  }
`

function UpdateTruck() {
  const { truckId } = useParams()
  let { truck } = useQuery(TruckQuery, { id: truckId })
  const updateTruck = useAction({
    mutation: UpdateTruckMutation,
    successMessage: "Le camion a bien été mis à jour.",
    redirectTo: "/admin/trucks",
  })

  truck = produce(truck, (draft) => {
    delete draft.__typename
  })

  const handleSubmit = async (truck) => {
    await updateTruck({
      truck: {
        id: truckId,
        ...truck,
      },
    })
  }

  return (
    <>
      <Typography variant="h2">Éditer un camion</Typography>

      <TruckForm truck={truck} onSubmit={handleSubmit} />
    </>
  )
}

export default UpdateTruck
