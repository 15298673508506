import { Typography } from "@material-ui/core"
import InnerLayout from "../components/seller/InnerLayout"
import AppBar from "../components/layout/AppBar"

function NoProducts() {
  return (
    <>
      <AppBar />
      <InnerLayout>
        <Typography variant="h2">
          L'administrateur ne vous a attribué aucun rôle. Veuillez le contacter
          pour continuer.
        </Typography>
      </InnerLayout>
    </>
  )
}

export default NoProducts
