import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"
import PieChartCard from "../../components/manager/PieChartCard"
import { gql } from "urql"
import { useQuery } from "../../hooks"

function SellingPointCard({ sellingPoint }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">{sellingPoint.name}</Typography>
      </CardContent>

      <CardActions>
        <Button
          component={RouterLink}
          to={`/manager/selling-points/${sellingPoint.id}`}
        >
          Statistiques
        </Button>

        {sellingPoint.type === "STAND" && (
          <Button
            component={RouterLink}
            to={`/manager/selling-points/${sellingPoint.id}/end-of-day`}
            disabled={
              sellingPoint?.currentSalesDay?.salesDayState !==
              "ManagerTotalChecking"
            }
          >
            Fin de journée
          </Button>
        )}

        {sellingPoint.type === "STORE" && (
          <Button
            component={RouterLink}
            to={`/manager/selling-points/${sellingPoint.id}/start-new-delivery`}
          >
            Nouvelle livraison
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

const SellingPointsQuery = gql`
  {
    me {
      managerOf {
        id
        name
        type

        currentSalesDay {
          salesDayState
        }

        currentPeriod {
          commission
          cash: totalCash
          card: totalCard
          check: totalCheck
        }
      }
    }
  }
`

function SellingPoints() {
  const {
    me: { managerOf: sellingPoints },
  } = useQuery(SellingPointsQuery)

  const total = {
    commission: 0,
    cash: 0,
    card: 0,
    check: 0,
  }
  for (const {
    currentPeriod: { commission, cash, card, check },
  } of sellingPoints) {
    total.commission += commission
    total.cash += cash
    total.card += card
    total.check += check
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Points de ventes
      </Typography>

      <Grid container spacing={3}>
        {sellingPoints.map((sellingPoint) => (
          <Grid item xs={12} md={6} key={sellingPoint.id}>
            <SellingPointCard sellingPoint={sellingPoint} />
          </Grid>
        ))}
      </Grid>

      <PieChartCard
        sx={{ mt: 3 }}
        values={total}
        title="Statistiques globales"
      />
    </>
  )
}

export default SellingPoints
