import { MenuItem } from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { gql } from "urql"
import { FormikTextField, FormikAutocomplete } from "../../components/fields"
import { useQuery } from "../../hooks"

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Veuillez saisir une adresse email valide")
    .required("Veuillez saisir une adresse email"),
  firstName: Yup.string().required("Veuillez saisir un prénom"),
  lastName: Yup.string().required("Veuillez saisir un nom"),
  phoneNumber: Yup.string().length(10),
  role: Yup.string(),
  managerOf: Yup.array(),
  sellerAt: Yup.array(),
})

const Query = gql`
  {
    me {
      role
    }

    sellingPoints {
      id
      name
    }
  }
`

function UserForm({ user, onSubmit }) {
  const {
    me: { role },
    sellingPoints,
  } = useQuery(Query)
  const isSuperAdmin = role === "SUPER_ADMIN"

  const sellingPointNameFromId = (sellingPointId) => {
    const sellingPoint = sellingPoints.find(
      (sellingPoint) => sellingPoint.id === sellingPointId
    )

    if (!sellingPoint) {
      return ""
    }

    return sellingPoint.name
  }

  const handleSubmit = async (data) => {
    if (!isSuperAdmin) {
      data = { ...data }
      delete data.role
    }

    if (data.role === "") {
      data.role = null
    }

    await onSubmit(data)
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={
        user ?? {
          email: "",
          firstName: "",
          lastName: "",
          phoneNumber: "06",
          role: "",
          managerOf: [],
          sellerAt: [],
        }
      }
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormikTextField
            name="email"
            label="Adresse email"
            type="email"
            disabled={!!user}
          />
          <FormikTextField name="firstName" label="Prénom" />
          <FormikTextField name="lastName" label="Nom" />
          <FormikTextField name="phoneNumber" label="Numéro de téléphone" />

          {isSuperAdmin && (
            <FormikTextField name="role" label="Rôle" select>
              <MenuItem value="">
                <em>Pas de rôle</em>
              </MenuItem>
              <MenuItem value="ADMIN">Administrateur</MenuItem>
              <MenuItem value="SUPER_ADMIN">Super-administrateur</MenuItem>
            </FormikTextField>
          )}

          <FormikAutocomplete
            name="managerOf"
            label="Responsable"
            multiple
            options={sellingPoints.map((sellingPoint) => sellingPoint.id)}
            getOptionLabel={sellingPointNameFromId}
          />

          <FormikAutocomplete
            name="sellerAt"
            label="Vendeur"
            multiple
            options={sellingPoints.map((sellingPoint) => sellingPoint.id)}
            getOptionLabel={sellingPointNameFromId}
          />

          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
            pending={isSubmitting}
          >
            {user ? "Éditer" : "Créer"}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  )
}

export default UserForm
