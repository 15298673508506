import { useState } from "react"
import { InputAdornment, IconButton } from "@material-ui/core"
import AddCircle from "@material-ui/icons/AddCircle"
import ContainerDialog from "./ContainerDialog"
import ContainerInnerField from "./ContainerInnerField"

function ContainerField({ name, containerCounts, ...props }) {
  const [dialogOpen, setDialogOpen] = useState(false)

  const totalCount = () => {
    let totalCount = 0
    for (const [, count] of Object.entries(containerCounts)) {
      totalCount += count
    }
    return totalCount
  }

  return (
    <>
      <ContainerDialog
        name={name}
        open={dialogOpen}
        total={totalCount()}
        onClose={() => setDialogOpen(false)}
        onValidate={() => setDialogOpen(false)}
      />

      <ContainerInnerField
        label="Total conteneur"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setDialogOpen(true)}>
                <AddCircle />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={totalCount()}
        {...props}
      />
    </>
  )
}

export default ContainerField
