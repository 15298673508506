import { forwardRef } from "react"
import { TextField } from "@material-ui/core"
import NumberFormat from "react-number-format"

const NumberFormatCustom = forwardRef(({ onChange, value, ...props }, ref) => {
  return (
    <NumberFormat
      {...props}
      value={value}
      getInputRef={ref}
      onValueChange={(values) =>
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }
      thousandSeparator=" "
      decimalSeparator="."
      decimalScale={2}
      suffix=" €"
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values

        return formattedValue === "" || floatValue <= 99999
      }}
      allowNegative={false}
    />
  )
})

function MoneyField({ InputProps, ...props }) {
  return (
    <TextField
      InputProps={{
        inputComponent: NumberFormatCustom,
        ...InputProps,
      }}
      style={{
        minWidth: "180px",
      }}
      inputMode="numeric"
      {...props}
    />
  )
}

export default MoneyField
