import { gql } from "urql"
import { useQuery } from "."

const roles = {
  admin: {
    role: "admin",
    title: "Admin",
    link: "/admin/alerts",
  },
  manager: {
    role: "manager",
    title: "Responsable",
    link: "/manager/selling-points",
  },
  seller: {
    role: "seller",
    title: "Vendeur",
    link: "/seller/selling-points",
  },
}

const UserRolesQuery = gql`
  {
    me {
      role
      sellerAt {
        id
      }
      managerOf {
        id
      }
    }
  }
`

function useUserRoles() {
  const { me } = useQuery(UserRolesQuery)

  const userRoles = []

  if (me.role) {
    userRoles.push(roles.admin)
  }

  if (me.managerOf.length > 0) {
    userRoles.push(roles.manager)
  }

  if (me.sellerAt.length > 0) {
    userRoles.push(roles.seller)
  }

  return userRoles
}

export default useUserRoles
