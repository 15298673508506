import { useState } from "react"
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"
import NumericField from "./NumericField"
import { moneyUnits, coinUnits, banknoteUnits } from "../../utils"

function CashFundDialog({ open, onClose, onValidate }) {
  const [cashFund, setCashFund] = useState(
    Object.fromEntries(moneyUnits.map((unit) => [unit.value, 0]))
  )

  const handleCashFundFieldChange = (unitValue, quantity) => {
    setCashFund({
      ...cashFund,
      [unitValue]: quantity,
    })
  }

  const total =
    Object.entries(cashFund).reduce(
      (total, [unitValue, quantity]) => total + unitValue * quantity,
      0
    ) / 100

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="cash-fund-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="cash-fund-dialog-title">Aide à la saisie</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {banknoteUnits.map(({ label, value: unitValue }) => (
              <NumericField
                key={label}
                label={label}
                onChange={(quantity) =>
                  handleCashFundFieldChange(unitValue, quantity)
                }
                value={cashFund[unitValue]}
              />
            ))}
          </Grid>

          <Grid item xs={12} md={6}>
            {coinUnits.map(({ label, value: unitValue }) => (
              <NumericField
                key={label}
                label={label}
                onChange={(value) =>
                  handleCashFundFieldChange(unitValue, value)
                }
                value={cashFund[unitValue]}
              />
            ))}
          </Grid>
        </Grid>

        <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
          Total : {total} €
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>

        <Button variant="contained" onClick={() => onValidate("" + total)}>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CashFundDialog
