import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"
import FormikNumericField from "./FormikNumericField"
import { containers } from "../../utils"

function ContainerDialog({ name, total, open, onClose, onValidate }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="cash-fund-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="cash-fund-dialog-title">Aide à la saisie</DialogTitle>

      <DialogContent>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} md={6}>
            {containers.map((container) => (
              <FormikNumericField
                name={`${name}[${container.name}]`}
                key={`${name}[${container.name}]`}
                label={container.name}
              />
            ))}
          </Grid>
        </Grid>

        <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
          Total : {total} conteneur{total > 1 ? "s" : ""}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>

        <Button variant="contained" onClick={onValidate}>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ContainerDialog
