import { Typography } from "@material-ui/core"
import { gql } from "urql"
import { useAction } from "../../../hooks"
import ProductForm from "../../../components/admin/ProductForm"

const CreateProductMutation = gql`
  mutation ($product: CreateProductInput!) {
    createProduct(product: $product) {
      id
    }
  }
`

function CreateProduct() {
  const createProduct = useAction({
    mutation: CreateProductMutation,
    successMessage: "Le produit a bien été créé.",
    redirectTo: "/admin/products",
  })

  const handleSubmit = async (product) => {
    await createProduct({ product })
  }

  return (
    <>
      <Typography variant="h2">Créer un produit</Typography>

      <ProductForm onSubmit={handleSubmit} />
    </>
  )
}

export default CreateProduct
