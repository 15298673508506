import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import { Link as RouterLink, useParams } from "react-router-dom"
import { gql } from "urql"

import { useQuery, useMutation } from "../../../../../hooks"
import DeleteButton from "../../../../../components/admin/DeleteButton"
import FabCreate from "../../../../../components/admin/FabCreate"
import { format } from "date-fns"
import { fr } from "date-fns/locale/index.js"

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

function UpdateSalesDayButton({ sellingPoint, salesDay }) {
  return (
    <IconButton
      id={`update-${salesDay.id}`}
      size="small"
      component={RouterLink}
      to={`/admin/selling-points/${sellingPoint.id}/sales-days/${salesDay.id}/update`}
    >
      <EditIcon />
    </IconButton>
  )
}

const deleteSalesDayMutation = gql`
  mutation ($id: ID!) {
    deleteSalesDay(id: $id) {
      id
    }
  }
`

function DeleteSalesDayButton({ salesDay: { id } }) {
  const deleteSalesDay = useMutation(deleteSalesDayMutation)

  return (
    <DeleteButton id={`delete-${id}`} onClick={() => deleteSalesDay({ id })} />
  )
}

const sellingPointSalesDaysQuery = gql`
  query ($sellingPointId: ID!) {
    sellingPoint(sellingPoint: { id: $sellingPointId }) {
      id
      name
    }
    salesDays: sellingPointSalesDays(sellingPointId: $sellingPointId) {
      id
      salesDayState
      salesDayStateLabel
      sellers {
        id
        fullName
      }
      closedBy {
        id
        fullName
      }
      turnover
      date
      closedAt
    }
  }
`

function SalesDaySalesDays() {
  const { sellingPointId } = useParams()
  const { sellingPoint, salesDays } = useQuery(sellingPointSalesDaysQuery, {
    sellingPointId,
  })

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Liste des journées de vente de {sellingPoint.name}
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="Selling points">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Responsable</TableCell>
              <TableCell>Vendeurs</TableCell>
              <TableCell>Chiffre d'affaire</TableCell>
              <TableCell>Statut</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {salesDays.map((salesDay) => (
              <TableRow
                id={`get-${salesDay.id}`}
                key={salesDay.id}
                sx={hideLastBorder}
              >
                <TableCell>
                  {format(new Date(salesDay.date), "PPP", {
                    locale: fr,
                  })}
                </TableCell>
                <TableCell>{salesDay?.closedBy?.fullName}</TableCell>
                <TableCell>
                  {salesDay?.sellers
                    ?.map(({ fullName }) => fullName)
                    .join(", ")}
                </TableCell>
                <TableCell>{salesDay?.turnover / 100}€</TableCell>
                <TableCell>{salesDay?.salesDayStateLabel}</TableCell>
                <TableCell sx={{ py: 0 }}>
                  <UpdateSalesDayButton
                    sellingPoint={sellingPoint}
                    salesDay={salesDay}
                  />
                  <DeleteSalesDayButton salesDay={salesDay} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <FabCreate route="/admin/selling-points/create" />
    </>
  )
}

export default SalesDaySalesDays
