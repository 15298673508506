import { useState } from "react"
import { InputAdornment, IconButton } from "@material-ui/core"
import EuroIcon from "@material-ui/icons/Euro"
import { useField } from "formik"
import CashFundDialog from "./CashFundDialog"
import MoneyField from "./MoneyField"

function FormikCashFundField({ helperText, ...props }) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [field, { error, touched }, { setValue }] = useField(props)

  const handleDialogValidation = (value) => {
    setValue(value)
    setDialogOpen(false)
  }

  const hasError = touched && Boolean(error)

  return (
    <>
      <CashFundDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onValidate={handleDialogValidation}
      />

      <MoneyField
        {...field}
        label="Total fond de caisse"
        error={hasError}
        helperText={hasError ? error : helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setDialogOpen(true)}>
                <EuroIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </>
  )
}

export default FormikCashFundField
