import { useState } from "react"
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  TableFooter,
  TablePagination,
  Button,
} from "@material-ui/core"

import { useHistory, useParams } from "react-router-dom"
import { gql } from "urql"
import { useQuery, useMutation } from "../../../../hooks"
import { Formik, Form } from "formik"

import {
  FormikTextField,
  FormikMoneyField,
} from "../../../../components/fields"
import { priceToString } from "../../../../utils/price"
import { format } from "date-fns"
import { fr } from "date-fns/locale/index.js"
import EditIcon from "@material-ui/icons/Edit"
import DeleteButton from "../../../../components/admin/DeleteButton"
import { LoadingButton } from "@material-ui/lab"

const DeleteSaleMutation = gql`
  mutation ($saleId: ID!) {
    deleteSale(sale: { id: $saleId }) {
      id
    }
  }
`
const UpdateSaleMutation = gql`
  mutation ($sale: UpdateSaleInput!) {
    updateSale(sale: $sale) {
      id
    }
  }
`
// const DeleteSaleMutation = gql``

const SalesDayQuery = gql`
  query ($salesDayId: ID!) {
    salesDay(id: $salesDayId) {
      id
      date
      sales {
        id
        seller {
          id
          fullName
        }
        paymentMethod
        date
        totalPrice
      }
      sellingPoint {
        id
        sellers {
          id
          fullName
        }
      }
    }
  }
`

const hideLastBorder = {
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}

const paymentMethods = {
  CASH: "Cash",
  CARD: "Carte",
  CHECK: "Chèque",
}

function UpdateSale({ sale, sellers }) {
  const [open, setOpen] = useState(false)
  const updateSale = useMutation(UpdateSaleMutation)

  async function handleSubmit({ ...sale }) {
    sale.totalPrice *= 100
    await updateSale({ sale })
    setOpen(false)
  }

  return (
    <>
      <IconButton
        id={`update-${sale.id}`}
        size="small"
        onClick={() => setOpen(true)}
      >
        <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Modifier le ticket du {format(new Date(sale.date), "HH:mm")}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              id: sale.id,
              seller: sale.seller.id,
              paymentMethod: sale.paymentMethod,
              totalPrice: sale.totalPrice / 100,
            }}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormikTextField name="seller" label="Vendeur" select>
                  {sellers.map(({ id, fullName }) => (
                    <MenuItem key={id} value={id}>
                      {fullName}
                    </MenuItem>
                  ))}
                </FormikTextField>
                <FormikTextField
                  name="paymentMethod"
                  label="Moyen de paiement"
                  select
                >
                  {Object.entries(paymentMethods).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </FormikTextField>
                <FormikMoneyField name="totalPrice" label="Total" />

                <LoadingButton
                  pending={isSubmitting}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  {"Éditer"}
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

function Sales() {
  const history = useHistory()
  const { sellingPointId, salesDayId } = useParams()
  const { salesDay } = useQuery(SalesDayQuery, { salesDayId })
  const deleteSale = useMutation(DeleteSaleMutation)

  const [page, setPage] = useState(0)

  function pushToCashRegisterView() {
    history.push(`/seller/selling-points/${sellingPointId}/${salesDayId}`)
  }

  return (
    <>
      <Typography variant="h2" marginBottom>
        Les ventes du {format(new Date(salesDay.date), "PPP", { locale: fr })}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Heure</TableCell>
              <TableCell>Vendeur</TableCell>
              <TableCell>Moyen de paiement</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {salesDay.sales.map((sale) => (
              <TableRow key={sale.id} sx={hideLastBorder}>
                <TableCell component="th" scope="row">
                  {format(new Date(sale.date), "HH:mm")}
                </TableCell>
                <TableCell>{sale.seller.fullName}</TableCell>
                <TableCell>{paymentMethods[sale.paymentMethod]}</TableCell>
                <TableCell>{priceToString(sale.totalPrice)}</TableCell>
                <TableCell>
                  <UpdateSale
                    sale={sale}
                    sellers={salesDay.sellingPoint.sellers}
                  />
                  <DeleteButton
                    id={`delete-${sale.id}`}
                    onClick={() => deleteSale({ saleId: sale.id })}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                count={salesDay.sales.length}
                rowsPerPage={10}
                page={page}
                onPageChange={(event, page) => setPage(page)}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Button
        onClick={pushToCashRegisterView}
        variant="contained"
        sx={{ mt: 2 }}
      >
        Revenir à la caisse enregistreuse
      </Button>
    </>
  )
}

export default Sales
