import * as React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar as MaterialUIAppBar,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Divider,
  Menu,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import SignOutButton from "../SignOutButton"
import { useRouteMatch, Link as RouterLink } from "react-router-dom"
import { useUserRoles } from "../../hooks"

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  title: {
    flexGrow: 1,
  },
}))

function MenuItemLink({ to, children, ...props }) {
  const match = useRouteMatch(to)

  return (
    <MenuItem
      {...props}
      component={RouterLink}
      selected={match?.isExact}
      to={to}
    >
      {children}
    </MenuItem>
  )
}

function RoleMenuListItem() {
  const userRoles = useUserRoles()

  if (userRoles.length === 0) {
    return null
  }

  return (
    <>
      {userRoles.map(({ role, link, title }) => (
        <MenuItemLink key={link} to={link}>
          {title}
        </MenuItemLink>
      ))}
      <Divider style={{ marginTop: 5, marginBottom: 5 }} />
    </>
  )
}

function MenuButton() {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        id="appBarMenu"
        edge="end"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <RoleMenuListItem />
        <MenuItemLink to="/user/profile">Mon Profil</MenuItemLink>
        <SignOutButton label="Déconnexion" />
      </Menu>
    </>
  )
}

function AppBar({ onDrawerToggle, children }) {
  const classes = useStyles()

  const handleDrawerToggle = onDrawerToggle

  return (
    <MaterialUIAppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <Typography
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          EasyCaisse
        </Typography>
        {children}
        <MenuButton />
      </Toolbar>
    </MaterialUIAppBar>
  )
}

export default AppBar
