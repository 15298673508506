import { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container } from "@material-ui/core"
import ListAltIcon from "@material-ui/icons/ListAlt"
import StorefrontIcon from "@material-ui/icons/Storefront"
import AssignmentIcon from "@material-ui/icons/Assignment"
import BarChartIcon from "@material-ui/icons/BarChart"
import ReceiptIcon from "@material-ui/icons/Receipt"
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom"
import { gql } from "urql"
import { useQuery } from "../hooks"
import AppBar from "../components/layout/AppBar"
import Drawer from "../components/layout/Drawer"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  main: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
  },

  toolbar: theme.mixins.toolbar,
}))

const ManagerQuery = gql`
  {
    me {
      managerOf {
        id
        name
        type
        currentSalesDay {
          salesDayState
        }
      }
    }
  }
`

function Manager({ children }) {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const {
    me: { managerOf: sellingPoints },
  } = useQuery(ManagerQuery)

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen)
  }

  const links = [
    {
      name: "Points de ventes",
      to: "/manager/selling-points",
      icon: <ListAltIcon />,
    },
    {
      name: "Ventes en cours",
      icon: <ReceiptIcon />,
      to: "/manager/sales-days/current",
    },
    {
      name: "Journées de vente à cloturer",
      icon: <MeetingRoomIcon />,
      to: "/manager/sales-days/unclosed",
    },
  ]

  links.push(
    ...sellingPoints.map((sellingPoint) => {
      const store = {
        name: sellingPoint.name,
        icon: <StorefrontIcon />,
        links: [
          {
            name: "Statistiques",
            to: `/manager/selling-points/${sellingPoint.id}`,
            icon: <BarChartIcon />,
          },
        ],
      }
      if (sellingPoint.type === "STORE") {
        store.links.push({
          name: "Nouvelle livraison",
          to: `/manager/selling-points/${sellingPoint.id}/start-new-delivery`,
          icon: <AssignmentIcon />,
          disabled: sellingPoint?.currentSalesDay?.salesDayState === "Closed",
        })
      } else {
        // store.links.push({
        //   name: "Fin de journée",
        //   to: `/manager/selling-points/${sellingPoint.id}/end-of-day`,
        //   icon: <AssignmentIcon />,
        //   disabled:
        //     sellingPoint?.currentSalesDay?.salesDayState !==
        //     "ManagerTotalChecking",
        // })
      }
      return store
    })
  )

  return (
    <div className={classes.root}>
      <AppBar links={links} onDrawerToggle={handleDrawerToggle} />
      <Drawer open={drawerOpen} onClose={handleDrawerToggle} links={links} />

      <Container component="main" maxWidth="md" className={classes.main}>
        <div className={classes.toolbar} />

        {children}
      </Container>
    </div>
  )
}

export default Manager
