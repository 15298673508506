import { useState } from "react"
import { InputAdornment, IconButton } from "@material-ui/core"
import EuroIcon from "@material-ui/icons/Euro"
import CashFundDialog from "./CashFundDialog"
import MoneyField from "./MoneyField"

function CashFundField({ onChange, ...props }) {
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleDialogValidation = (value) => {
    onChange({
      target: {
        name: props.name,
        value,
      },
    })
    setDialogOpen(false)
  }

  return (
    <>
      <CashFundDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onValidate={handleDialogValidation}
      />

      <MoneyField
        label="Total fond de caisse"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setDialogOpen(true)}>
                <EuroIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={onChange}
        {...props}
      />
    </>
  )
}

export default CashFundField
