import { Typography } from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { useParams } from "react-router-dom"
import { gql } from "urql"
import { useAction, useQuery } from "../../hooks"
import {
  FormikCashFundDetailsField,
  FormikMoneyField,
} from "../../components/fields"
import { defaultMoneyValues } from "../../utils/moneyUnits"

const validationSchema = Yup.object({
  totalCash: Yup.object().test(
    "totalCash",
    "Veuillez saisir la fin de caisse avec l'aide à la saisie",
    (cashFundDetails) => {
      let totalCash = 0

      for (let field in cashFundDetails) {
        totalCash += cashFundDetails[field] * field
      }

      return totalCash !== 0
    }
  ),
  totalCard: Yup.number().required("Veuillez saisir le total TPE"),
  totalCheck: Yup.number().required("Veuillez saisir le total chèques"),
})

const ValidateSaleDayMutation = gql`
  mutation ($salesDay: ValidateSalesDayInput!) {
    validateSalesDay(salesDay: $salesDay) {
      id
    }
  }
`

const SalesDayQuery = gql`
  query ($salesDayId: ID!) {
    salesDay(id: $salesDayId) {
      id
      salesDayState
    }
  }
`
function EndOfDayForm() {
  const { salesDayId } = useParams()
  const {
    salesDay: { salesDayState },
  } = useQuery(SalesDayQuery, {
    salesDayId,
  })
  const validateSaleDay = useAction({
    mutation: ValidateSaleDayMutation,
    successMessage: "La journée de vente est bien clôturée.",
    redirectTo: "/manager/selling-points",
  })

  const handleSubmit = async (data) => {
    data = validationSchema.cast(data)
    data.totalCard = Math.round(data.totalCard * 100)
    data.totalCheck = Math.round(data.totalCheck * 100)

    const cashDetails = {}

    for (const [value, quantity] of Object.entries(data.totalCash)) {
      cashDetails[`unit${value}`] = Number(quantity)
    }

    await validateSaleDay({
      salesDay: {
        salesDayId,
        cashDetails,
        totalCard: data.totalCard,
        totalCheck: data.totalCheck,
      },
    })
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        totalCash: defaultMoneyValues,
        totalCard: "",
        totalCheck: "",
      }}
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <FormikCashFundDetailsField
            cashFundDetailsName="totalCash"
            values={values.totalCash}
            label="Fin de caisse"
          />
          <FormikMoneyField name="totalCard" label="Total TPE" />
          <FormikMoneyField name="totalCheck" label="Total chèques" />

          <LoadingButton
            disabled={salesDayState === "Closed"}
            pending={isSubmitting}
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
          >
            Valider
          </LoadingButton>
        </Form>
      )}
    </Formik>
  )
}

function EndOfDay() {
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Fin de journée
      </Typography>

      <EndOfDayForm />
    </>
  )
}

export default EndOfDay
