import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Fab from "@material-ui/core/Fab"
import AddIcon from "@material-ui/icons/Add"

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed !important",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

function FabCreate({ route }) {
  const classes = useStyles()

  const fab = {
    color: "secondary",
    className: classes.fab,
    icon: <AddIcon />,
    label: "Edit",
  }

  return (
    <Fab
      id="create"
      aria-label={fab.label}
      className={fab.className}
      color={fab.color}
      href={route}
    >
      {fab.icon}
    </Fab>
  )
}

export default FabCreate
