import { forwardRef } from "react"
import { TextField } from "@material-ui/core"
import NumberFormat from "react-number-format"

const NumberFormatCustom = forwardRef(({ onChange, ...props }, ref) => {
  return (
    <NumberFormat
      {...props}
      getInputRef={ref}
      onValueChange={(values) => onChange(Number(values.value))}
      thousandSeparator=" "
      decimalSeparator="."
      decimalScale={3}
      suffix=" kg"
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values

        return formattedValue === "" || floatValue <= 99999
      }}
      allowNegative={false}
    />
  )
})

function WeightField({ InputProps, ...props }) {
  return (
    <TextField
      InputProps={{
        inputComponent: NumberFormatCustom,
        ...InputProps,
      }}
      style={{
        minWidth: "180px",
      }}
      inputMode="numeric"
      {...props}
    />
  )
}

export default WeightField
