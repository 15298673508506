import { useState } from "react"
import { IconButton, CircularProgress } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import { useSetCurrentSnackbar } from "../../hooks"
import ConfirmationDialog from "./ConfirmationDialog"

function DeleteButton({ onClick, ...props }) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const setCurrentSnackbar = useSetCurrentSnackbar()

  const handleClick = () => {
    setDialogOpen(true)
  }

  const closeDialog = () => {
    setDialogOpen(false)
  }

  const handleDialogConfirm = async () => {
    setDeleting(true)

    try {
      await onClick()
    } catch (error) {
      setCurrentSnackbar({
        severity: "error",
        message: "Une erreur est survenue, veuillez réessayer.",
      })
    } finally {
      closeDialog()
      setDeleting(false)
    }
  }

  return (
    <>
      <IconButton
        size="small"
        onClick={handleClick}
        disabled={deleting}
        {...props}
      >
        {deleting ? (
          <CircularProgress color="inherit" size={18} />
        ) : (
          <DeleteIcon />
        )}
      </IconButton>

      <ConfirmationDialog
        action={deleting}
        open={dialogOpen}
        onCancel={closeDialog}
        onConfirm={handleDialogConfirm}
        text={"Êtes-vous certain de vouloir faire cette action ?"}
      />
    </>
  )
}

export default DeleteButton
