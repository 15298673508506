import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"

function ConfirmationDialog({
  open,
  action = false,
  onCancel,
  onConfirm,
  text,
}) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">Confirmation</DialogTitle>

      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button disabled={action} onClick={onCancel} id="dialogCancel">
          Annuler
        </Button>

        <LoadingButton id="dialogConfirm" pending={action} onClick={onConfirm}>
          Confirmer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
