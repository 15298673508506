import PropTypes from "prop-types"
import MaskedInput from "react-text-mask"
import { Input, InputLabel } from "@material-ui/core"
import { useField } from "formik"

function DatetimeMask(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      // ref={(ref) => {
      //   inputRef(ref ? ref.inputElement : null)
      // }}
      mask={[
        // Day
        /[0-9]/,
        /[0-9]/,
        "/",
        // Month
        /[0-9]/,
        /[0-9]/,
        "/",
        // Year
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        "-",
        // Hour
        /[0-9]/,
        /[0-9]/,
        ":",
        // Minute
        /[0-9]/,
        /[0-9]/,
      ]}
      showMask
    />
  )
}

DatetimeMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

export default function FormikDatetimeField({ helperText, ...props }) {
  const [field, { error, touched }] = useField(props)
  const hasError = touched && Boolean(error)

  return (
    <>
      <InputLabel>{props.label}</InputLabel>
      <Input
        inputComponent={DatetimeMask}
        {...field}
        error={hasError}
        helperText={hasError ? error : helperText}
        {...props}
      />
    </>
  )
}
