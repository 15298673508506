import { AuthCheck } from "reactfire"
import { Route, Redirect } from "react-router-dom"

function PrivateRoute({ children, ...props }) {
  return (
    <Route
      {...props}
      render={({ location }) => (
        <AuthCheck
          fallback={
            <Redirect
              to={{ pathname: "/auth/sign-in", state: { from: location } }}
            />
          }
        >
          {children}
        </AuthCheck>
      )}
    />
  )
}

export default PrivateRoute
