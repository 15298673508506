import { Typography } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { produce } from "immer"
import { gql } from "urql"
import { useQuery, useAction } from "../../../../hooks"
import SellingPointForm from "../../../../components/admin/SellingPointForm"

const SellingPointQuery = gql`
  query ($id: ID!) {
    sellingPoint(sellingPoint: { id: $id }) {
      id
      name
      type
      nextSellingPointId
      priceCategory
      commissionRate
      managers {
        id
      }
      sellers {
        id
      }
      offers {
        product {
          id
        }
        quantity
        offer
      }
      priceCategoryExceptions {
        product {
          id
        }
        priceCategory
      }
    }
  }
`

const UpdateSellingPointMutation = gql`
  mutation ($sellingPoint: UpdateSellingPointInput!) {
    updateSellingPoint(sellingPoint: $sellingPoint) {
      id
    }
  }
`

function UpdateSellingPoint() {
  const { sellingPointId } = useParams()
  let { sellingPoint } = useQuery(SellingPointQuery, { id: sellingPointId })
  const updateSellingPoint = useAction({
    mutation: UpdateSellingPointMutation,
    successMessage: "Le point de vente a bien été mis à jour.",
    redirectTo: "/admin/selling-points",
  })

  const handleSubmit = async (sellingPoint) => {
    await updateSellingPoint({
      sellingPoint: {
        id: sellingPointId,
        ...sellingPoint,
      },
    })
  }

  sellingPoint = produce(sellingPoint, (draft) => {
    delete draft.__typename

    if (draft.nextSellingPointId === null) {
      draft.nextSellingPointId = ""
    }

    draft.managers = draft.managers.map(({ id }) => id)
    draft.sellers = draft.sellers.map(({ id }) => id)
    draft.offers = draft.offers.map(
      ({ product: { id: productId }, offer, quantity }) => ({
        productId,
        offer: offer / 100,
        quantity
      })
    )
    draft.priceCategoryExceptions = draft.priceCategoryExceptions.map(
      ({ product: { id: productId }, priceCategory }) => ({
        productId,
        priceCategory,
      })
    )
  })

  return (
    <>
      <Typography variant="h2">Éditer un point de vente</Typography>

      <SellingPointForm sellingPoint={sellingPoint} onSubmit={handleSubmit} />
    </>
  )
}

export default UpdateSellingPoint
