const coinUnits = [
  {
    label: "2 €",
    value: 200,
  },
  {
    label: "1 €",
    value: 100,
  },
  {
    label: "50 centimes",
    value: 50,
  },
  {
    label: "20 centimes",
    value: 20,
  },
  {
    label: "10 centimes",
    value: 10,
  },
  {
    label: "5 centimes",
    value: 5,
  },
  {
    label: "2 centimes",
    value: 2,
  },
  {
    label: "1 centime",
    value: 1,
  },
]

const banknoteUnits = [
  {
    label: "500 €",
    value: 50000,
  },
  {
    label: "200 €",
    value: 20000,
  },
  {
    label: "100 €",
    value: 10000,
  },
  {
    label: "50 €",
    value: 5000,
  },
  {
    label: "20 €",
    value: 2000,
  },
  {
    label: "10 €",
    value: 1000,
  },

  {
    label: "5 €",
    value: 500,
  },
]

const moneyUnits = coinUnits.concat(banknoteUnits)

const defaultMoneyValues = Object.assign(
  {},
  ...moneyUnits.map(({ value }) => ({ [value]: 0 }))
)

export { moneyUnits, coinUnits, banknoteUnits, defaultMoneyValues }
